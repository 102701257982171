<template>
	<div class="imgList">
		<div class="img-item" v-for="(item, index) in allimgData" :class="{active:item.bOn}" :key="index" @click="checkImg(index)">
			<img :src="item.imgsrc" alt="">
		</div>
	</div>
</template>

<script>
	export default {
	    name:"FileManagement",
	    data(){
	        return{
	            arr:[],
	            allimgData:[
	                {
	                    id:'001',
	                    imgsrc:"http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
	                },
	                   {
	                    id:'002',
	                    imgsrc:"http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
	                },
	                   {
	                    id:'003',
	                    imgsrc:"http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
	                },
	                   {
	                    id:'004',
	                    imgsrc:"http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
	                }
	            ]
	        }
	    },
	    mounted () {
	        this.allimgData.map(function (value) {
	            value.bOn = false
	        })
	    },
	    methods:{
	        checkImg(index){
	            const temp = this.allimgData[index]
	            temp.bOn = !this.allimgData[index].bOn
	            this.$set(this.allimgData, index, temp)
	        }
	    }
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.imgList{
		width: 98%;
		margin: 20px 0;
		background-color: #FFF;
		display: flex;
		flex-wrap: wrap;
		.active{
			display: block;
			width: 196px;
			height: 196px;
			line-height: 0;
			font-size: 0;
			vertical-align: middle;
			border: 3px solid #2b89fd;
			-webkit-transform: rotate(0deg);
			box-sizing: border-box;
		}
		.active::before{
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			border: 12px solid #2b89fd;
			border-top-color: transparent;
			border-left-color: transparent;
			box-sizing: border-box;
		}
		.active::after{
			content: "";
			display: block;
			width: 5px;
			height: 10px;
			position: absolute;
			right: 4px;
			bottom: 5px;
			border: 1px solid #fff;
			border-top-color: transparent;
			border-left-color: transparent;
			transform: rotate(45deg);
			cursor: pointer;
			box-sizing: border-box;
		} 
		.img-item{
			width: 196px;
			height: 196px;
			overflow: hidden;
			background-color: #FFFFFF;
			margin: 20px;
			cursor: pointer;
			img{
				height: 196px;
			}		   
		}
	}
</style>