<template>
	<div>
		<div class="cardCon flexB">
			<div class="flexA">
				<el-input placeholder="姓名查询" style="width: 200px;" class="marginR10" v-model="pushData.name" @keyup.enter="search()" />
				<el-input placeholder="手机号查询" style="width: 200px;" class="marginR10" v-model="pushData.phoneNumber" @keyup.enter="search()" />
			</div>
			<div class="flexA">
				<el-button type="primary" color='#112DF2' @click="search()">搜索</el-button>
				<el-button type="warning" @click="add">新增</el-button>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="name" label="姓名" />
			<el-table-column prop="phoneNumber" label="手机号" />
		    <el-table-column prop="state" label="状态">
				<template #default="{row}">
					{{row.state == 'ON_JOB'?'在职':row.state == 'LEAVE_JOB'?'离职':''}}
				</template>
			</el-table-column>
			<!-- <el-table-column prop="createdTime" label="最后更新"></el-table-column> -->
			<el-table-column label="操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="editEmployee(row)">编辑</el-button>
					<el-button link type="primary" plain color="#112DF2" @click="resetPwd(row)">重置密码</el-button>
					<el-button link type="primary" plain color="#112DF2" @click="updateNum(row)">修改手机号</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="sizes,prev, pager, next" :total="total">
			</el-pagination>
		</div>
		<employee-create :dialog-visible.sync='dialogVisible' :type='employeeType' :record-object='detailSearchData' :visiFlag="visiFlag"></employee-create>
		<update-pwd :dialog-visible.sync='dialogVisiblePwd' :type='employeeType' :record-object='detailSearchData' :visiFlag="visiFlag"></update-pwd>
		<update-tel :dialog-visible.sync='dialogVisibleNum' :type='employeeType' :record-object='detailSearchData' :visiFlag="visiFlag"></update-tel>
	</div>
</template>

<script>
	import updateTel from "@/components/PopTable/updateTel"
	import updatePwd from "@/components/PopTable/updatePwd"
	import employeeCreate from "@/components/PopTable/employeeCreate"
	import {employeePageList} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	export default {
		components:{employeeCreate,updatePwd,updateTel},
		data () {
			return {
				dialogVisible:false,
				dialogVisiblePwd:false,
				dialogVisibleNum:false,
				pushData:{
					name:'',
					phoneNumber:''
				},
				employeeType:'add',
				detailSearchData:{},
				tableData:[
					{id:1,name:'钱幼珊',phoneNumber:'17748337009',state:'ON_JOB'},
					{id:2,name:'穆怀蝶',phoneNumber:'18189911293',state:'ON_JOB'},
					{id:3,name:'赵曼荷',phoneNumber:'18089754106',state:'LEAVE_JOB'},
				],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				employeePageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			updateNum(item){
				this.detailSearchData = item
				// this.employeeType = 'edit'
				this.dialogVisibleNum = true
			},
			resetPwd(item){
				this.detailSearchData = item
				// this.employeeType = 'edit'
				this.dialogVisiblePwd = true
			},
			editEmployee(item){
				this.detailSearchData = item
				this.employeeType = 'edit'
				this.dialogVisible = true
			},
			visiFlag(val,type){
				this.dialogVisible = val
				this.dialogVisiblePwd = val
				this.dialogVisibleNum = val
				if(type){
					this.search()
				}
			},
			add(){
				this.detailSearchData = {}
				this.employeeType = 'add'
				this.dialogVisible = true
				// this.$router.push('/distributionManagement/creatCode/')
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 98%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>