<template>
	<div class="mainWrap">
		<div class="mainWrap flexB marginB24">
			<h3>{{edit?'编辑':'新增'}}秒杀活动</h3>
			<div class="footerBtn" v-if="step==1">
				<el-button @click="() => this.$router.go(-1)">取 消</el-button>
				<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">下一步</el-button>
			</div>
			<div class="footerBtn" v-if="step==2">
				<el-button type="primary" color='#112DF2' @click="lastStep">上一步</el-button>
				<el-button type="primary" color='#112DF2' @click="save" :loading="loading">保存</el-button>
			</div>
		</div>
		<el-form :model="pushData" ref="formBox" :rules="rules" label-width="150px" class="formBox">
			<el-row :gutter="16" v-if="step==1">
				<el-col :span="13">
					<el-form-item label="活动名称" prop="name">
						<el-input v-model="pushData.name" maxlength="20" placeholder="请输入活动名称"></el-input>
					</el-form-item>
					<!-- <el-form-item label="报名时间" prop="registrationTime">
						<el-date-picker v-model="registrationTime"
							type="daterange" value-format="YYYY-MM-DD" start-placeholder="选择报名开始日期"
							end-placeholder="选择报名结束日期" :disabled-date="disabledDate" />
					</el-form-item> -->
					<el-form-item label="活动时间" prop="activityTime">
						<el-date-picker v-model="activityTime"
							type="daterange" value-format="YYYY-MM-DD" start-placeholder="选择活动开始日期"
							end-placeholder="选择活动结束日期" :disabled-date="disabledDateA" />
					</el-form-item>
					<el-form-item label="活动楼位" prop="serial">
						<el-input v-model="pushData.serial" placeholder="输入活动楼位"></el-input>
					</el-form-item>
					<el-form-item label="活动可参与次数" prop="rule.activityParticipationNumber">
						<el-select v-model="pushData.rule.activityParticipationNumber" clearable class="marginR10">
							<el-option v-for="item in list" :key="item.num"
								:label='item.name' :value="item.num"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="单个商品参与次数" prop="rule.goodsParticipationNumber">
						<el-select v-model="pushData.rule.goodsParticipationNumber" clearable class="marginR10">
							<el-option v-for="item in numList" :key="item"
								:label='item' :value="item"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-form-item label="活动图片" style="width:90%;" prop='fileListData'>
					<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="activityUploadUrl" :file-list="fileListData" multiple accept="image/jpg,image/jpeg,image/png" list-type="picture-card" :limit="6" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
						<el-icon><Plus /></el-icon>
					</el-upload>
					<div class="tips">
						<div>图片注意：</div>	
						<div>1、建议尺寸：350px*120px；</div>
						<div>2、建议格式：jpg、png、jpeg</div>
						<div>3、建议数量：1张；</div>
					</div>	
					<el-dialog v-model="dialogVisible">
						<img w-full :src="dialogImageUrl" alt="Preview Image" />
					</el-dialog>
				</el-form-item>
			</el-row>
			<el-row v-if="step==2">
				<el-form-item label="活动商品">
					<el-button type="primary" link @click="addGoods()">+ 添加活动商品</el-button>
					<el-table :data="goods" border :empty-text="emptyText" style="width:1000px;margin-top: 15px">
						<el-table-column align="center" prop="name" label="服务项目/零售产品/卡项" />
						<el-table-column align="center" prop="typeStr" label="类型" />
						<el-table-column align="center" prop="salePrice" label="零售价" />
						<el-table-column align="center" prop="virtualSalesVolume" label="虚拟销量">
							<template #default="scope">
								<el-input v-model="scope.row.virtualSalesVolume" @input="virtualSalesVolume(scope.$index,scope.row.virtualSalesVolume)" @blur="virtualSalesVolumeBlur(scope.$index,scope.row.virtualSalesVolume,scope.row.virtualSalesVolumeOld)" @keyup="scope.row.activePrice=scope.row.activePrice.replace(/\D/g,'')" placeholder="请输入虚拟销量">
								</el-input>
							</template>
						</el-table-column>
						<el-table-column align="center" prop="activePrice" label="秒杀价">
							<template #default="scope">
								<el-input v-model="scope.row.activePrice" @input="groupPrice(scope.$index,scope.row.activePrice)" @blur="groupPriceBlur(scope.$index,scope.row.activePrice)" @keyup="scope.row.activePrice=scope.row.activePrice.replace(/[^\d\.]/g,'')" placeholder="秒杀价">
									<template #append>元</template>
								</el-input>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作">
							<template #default="scope">
								<el-button link type="danger" @click="removeProduct(scope.$index)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-row>
		</el-form>
		<select-goods :dialog-visible.sync="isSelect" ref="child" :close="mergeList" :isShow='true'
			:sel-list.sync="selList" :visiFlag="visiFlag"></select-goods>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {activityBeginForm,activityEndForm,formatDate,typeStr} from '@/utils/data'
	import selectGoods from "@/components/PopTable/selectGoods";
	import {activityUploadUrl,activityCreate,userLogin,activityInfo,activityUpdate} from '@/api'
	export default{
		components: {selectGoods},
		data (){
			return{
				activityUploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'activity/'},
				list:[{name:'不限',num:-1},{name:'1次',num:1},{name:'2次',num:2},{name:'3次',num:3},{name:'4次',num:4},{name:'5次',num:5},
					{name:'6次',num:6},{name:'7次',num:7},{name:'8次',num:8},{name:'9次',num:9}],
				numList:[1,2,3,4,5,6,7,8,9],
				step:1,
				fileListData:[],
				imgUrl:[],
				dialogImageUrl:'',
				dialogVisible:false,
				registrationTime:[],
				activityTime:[],
				pushData:{
					name:'',
					type:1,
					resources:[],
					rule:{
						activityParticipationNumber:-1,
						goodsParticipationNumber:1
					}
				},
				rules: {
					name: [{required: true, message: '请输入活动名称', trigger: 'blur'}],
					serial: [{required: true,message: '请输入活动楼层',validator: Validate.inventoryNum, trigger: 'blur'}],
					fileListData:[{}],
					'rule.activityParticipationNumber':[{required: true, message: '请选择活动可参与次数', trigger: 'blur'}],
					'rule.goodsParticipationNumber':[{required: true, message: '请选择单个商品参与次数', trigger: 'blur'}]
				},
				loading: false,
				edit: false,
				goods:[],
				isSelect:false,
				emptyText:'暂无数据',
				selList:[],
				type:1
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			if(this.$route.params.id){
				this.edit = true
				this.getDate(this.$route.params.id)
			}
		},	
		methods:{
			virtualSalesVolume(index,val){
				if(val == "" || val == null){
					this.goods[index].virtualSalesVolume = 0
					return
				}
				this.goods[index].virtualSalesVolume = val
			},
			virtualSalesVolumeBlur(index,val,old){
				if(Number(val)<Number(old)&&(Number(old)>0)&&(Number(val)!=Number(old))){
					this.$message({
						showClose: true,
						message: "新设置的活动商品虚拟销量不能小于上次设置的值",
						type: "warning",
					});
					this.goods[index].virtualSalesVolume = old
				}
			},
			getDate(id){
				activityInfo({data:{id}}).then(res=>{
					let data = res.data
					this.registrationTime = [formatDate(data.registrationTimeBegin),formatDate(data.registrationTimeEnd)]
					this.activityTime = [formatDate(data.activityTimeBegin),formatDate(data.activityTimeEnd)]
					if(data.resources&&data.resources.length>0){
						data.resources.forEach(ies =>{
							this.fileListData.push({url: ies.resource});
							this.imgUrl.push(ies.resource)
						});
					}
					delete data.resources
					delete data.activityTimeBegin
					delete data.activityTimeEnd
					delete data.registrationTimeBegin
					delete data.registrationTimeEnd
					data.serial = data.serial.toString()
					let goods = []
					let arr = []
					data.goods.forEach(item=>{
						goods.push({
							id:item.goodsId,
							name: item.goodsName,
							typeStr: typeStr(item.goodsType),
							salePrice: item.goodsSalePrice,
							activeGoodsType:item.goodsType,
							virtualSalesVolume:item.virtualSalesVolume,
							virtualSalesVolumeOld:item.virtualSalesVolume,
							activePrice:item.activePrice
						})
						arr.push({
							id:item.goodsId,
							name: item.goodsName,
							typeStr: typeStr(item.goodsType),
							salePrice: item.goodsSalePrice,
							activeGoodsType:item.goodsType
						})
					})
					this.selList = arr
					this.goods = goods
					this.pushData = data
					delete data.goods
				})
			},
			groupPrice(index,val){
				if(val>99999.99)val=99999.99;
				if(val<0)val=0;
				this.goods[index].activePrice = val
			},
			groupPriceBlur(index,val){
				val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
				this.goods[index].activePrice = val
			},
			addGoods(){
				this.isSelect = true
				this.$refs.child.search(this.type)
			},
			disabledDate(time) {
				return time.getTime() < Date.now() - 8.64e7
			},
			disabledDateA(time) {
				return time.getTime() < Date.now() - 8.64e7
				// return time.getTime() < Date.now()
			},
			lastStep(){
				this.step = 1
			},
			// 确认提交
			submit() {
				// if(!(this.registrationTime&&this.registrationTime.length>0)){
				// 	this.$message({
				// 		showClose: true,
				// 		message: "请选择报名时间",
				// 		type: "warning",
				// 	});
				// 	return
				// }
				if(!(this.activityTime&&this.activityTime.length>0)){
					this.$message({
						showClose: true,
						message: "请选择活动时间",
						type: "warning",
					});
					return
				}
				if(!(this.imgUrl&&this.imgUrl.length>0)){
					this.$message({
						showClose: true,
						message: "请上传图片",
						type: "warning",
					});
					return
				}
				// if(new Date(this.registrationTime[1]).getTime() > new Date(this.activityTime[0]).getTime()){
				// 	this.$message({
				// 		showClose: true,
				// 		message: "活动开始时间不能小于报名结束时间",
				// 		type: "warning",
				// 	});
				// 	return
				// }
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						this.step = 2
					}
				})
			},
			save(){
				this.pushData.resources = this.imgUrl
				if (this.goods.length == 0) {
					this.$message({
						showClose: true,
						message: "请选择活动商品",
						type: "warning",
					});
					return;
				}
				for(let i of this.goods){
					let val = Number(i.activePrice)
					let val1 = Number(i.salePrice)
					if(isNaN(val) || val == "" || val == null || val <= 0){
						this.$message({
							showClose: true,
							message: "请填写秒杀价",
							type: "warning",
						});
						return;
					}
					if(val1<val){
						this.$message({
							showClose: true,
							message: "秒杀价不能大于原价",
							type: "warning",
						});
						return;
					}
				}
				let arr = []
				this.goods.forEach(item=>{
					arr.push({
						goodsId:item.id,
						goodsType:item.activeGoodsType,
						activePrice:item.activePrice,
						virtualSalesVolume:item.virtualSalesVolume
					})
				})
				let postData = {
					goods:arr,
					"registrationTimeBegin": activityBeginForm(this.registrationTime[0]),
					"registrationTimeEnd": activityEndForm(this.registrationTime[1]),
					"activityTimeBegin": activityBeginForm(this.activityTime[0]),
					"activityTimeEnd": activityEndForm(this.activityTime[1])
				}
				postData = Object.assign(postData, this.pushData)
				let postUrl = activityCreate
				if(this.$route.params.id){
					postData.id = this.pushData.id
					postUrl = activityUpdate
				}
				this.loading = true
				postUrl(postData).then(res=>{
					if(res.success){
						this.$router.replace('/active/flashSaleList')
					}else{
						this.loading = false
						this.$message.error(res.msg)
					}
				})
			},
			removeProduct(index) {
				this.goods.splice(index, 1);
				this.selList.splice(index, 1);
			},
			visiFlag(num,val){
				this.type = num
				this.isSelect = val
			},
			mergeList(data) {
				let goods = JSON.parse(JSON.stringify(this.goods));
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < goods.length; j++) {
						if (data[i].id === goods[j].id) {
							arr.push(goods[j]);
							break;
						}
						if (j == goods.length - 1) {
							pushState = true;
						}
					}
					if (pushState || goods.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							typeStr: data[i].typeStr,
							salePrice: data[i].salePrice,
							activeGoodsType:data[i].activeGoodsType,
							virtualSalesVolume:0,
							virtualSalesVolumeOld:0,
							activePrice:''
						});
					}
				}
				this.goods = arr;
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || (file.response?ies.url==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || (file.response?ies==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || (file.response?ies==file.response.data[0]:'')){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 6 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const extension2 = file.name.endsWith(".jpeg")||file.name.endsWith(".JPEG");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2) {
					this.$message({
						message: "上传模板只能是 .jpg,.png,.jpeg 格式!",
						type: "error"
					});
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2M!");
				}
				return (extension || extension1 || extension2) && isLt2M;
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	::v-deep .el-form-item__content{
		display: block;
	}	
</style>