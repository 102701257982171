<template>
	<div>
		<h3 class="marginB20">数据分析</h3>
		<div class="marginB20">
			<h4 class="marginR10 marginB20">昨日核心数据</h4>
			<div class="flexA font12">
				<el-card>
					<div class="color666">
						<div class="marginB10">阅读（次）</div>
						<div class="flexA">
							<h4 class="marginR60 font16">77</h4>
							<div>较昨日<span style="color: palevioletred;"> +5</span></div>
						</div>
					</div>
					<div class="flexA">
						<div class="marginT30">
							<div class="flexA marginB20">
								<div class="marginR10">日</div>
								<div class="flexA">
									<!-- <el-icon color="red"><Bottom /></el-icon> -->
									<el-icon color='green'><Top /></el-icon>
									<span class="colorExceed">87.98%</span>
								</div>
							</div>
							<div class="flexA marginB20">
								<div class="marginR10">周</div>
								<div class="flexA">
									<el-icon color="red"><Bottom /></el-icon>
									<!-- <el-icon color='green'><Top /></el-icon> -->
									<span class="colorReduce">87.98%</span>
								</div>
							</div>
							<div class="flexA">
								<div class="marginR10">月</div>
								<div class="flexA">
									<!-- <el-icon color="red"><Bottom /></el-icon> -->
									<el-icon color='green'><Top /></el-icon>
									<span class="colorExceed">87.98%</span>
								</div>
							</div>
						</div>
						<div ref="echartsData" id="echartsData" style='height: 160px;width: 180px'></div>
					</div>
				</el-card>
			</div>
			<!-- <el-card class="marginB20">
				<div class="flexA color666">
					<div class="marginR60">
						<div>关注总人数</div>
						<div>{{dataInfo.subscribeQuantity}}</div>
					</div>
					<div class="marginR60">
						<div>阅读次数人数</div>
						<div>{{dataInfo.unsubscribeQuantity}}</div>
					</div>
					<div>
						<div>访问小程序人数</div>
						<div>{{dataInfo.registerQuantity}}</div>
					</div>
				</div>
			</el-card> -->
		</div>
		<div>
			<h5 class="marginR10 marginB20">流量分析</h5>
			<div class="flexA marginB20 font12 color666">
				<div class="marginR20">数据指标</div>
				<div class="flexA">
					<div v-for="item in indicators" :key="item.id" @click="getType(item.id)" :class="type==item.id?'dataActive':''" class="dataType">{{item.name}}</div>
				</div>
			</div>
			<div class="flexA marginB20 font12 color666">
				
				<div class="flexA marginR10">
					<div class="marginR20">数据时间</div>
					<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" :disabled-date="disabledDate" value-format="YYYY-MM-DD" start-placeholder="开始日期"
						end-placeholder="结束日期" :clearable="false" />
					<!-- <el-input placeholder="推广码名称" v-model="pushData.spreadQrcodeName" @keyup.enter="search()" style="width: 200px;">
						<template #suffix>
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search()"></component>
							</el-icon>
						</template>
					</el-input> -->
				</div>
			</div>
			<h5 class="marginR10">数据趋势</h5>
			<div ref="echartsContainer" id="echartsContainer" style='height: 300px;width: 100%'></div>
		</div>
		<h5 class="marginR10">内容排名</h5>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 10px;">
			<el-table-column prop="ranking" label="排名" min-width="70" />
			<el-table-column prop="spreadQrcodeName" label="内容标题" min-width="100" />
			<el-table-column prop="spreadQrcodeName" label="发布日期" min-width="100" />
		    <el-table-column prop="shopName" label="阅读次数" min-width="90" />
			<el-table-column prop="subscribeQuantity" label="阅读次数占比" min-width="90" />
			<el-table-column prop="unsubscribeQuantity" label="取关数量" min-width="120">
				<template #default="scope">
					<el-button link type="primary" plain color="#112DF2">详情</el-button>
					<el-button link type="primary" plain color="#112DF2">近14天趋势</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {spreadQrcodeDataChartAnalyse,spreadQrcodeDataStatistics} from '@/api'
	import * as echarts from 'echarts'
	// require(['echarts/theme/macarons'])
	import {dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				type:1,
				indicators:[
					{name:'阅读',id:1},
					{name:'分享',id:2},
					{name:'收藏',id:3},
					{name:'转发',id:4},
					{name:'评论',id:5},
					{name:'点赞',id:6},
					{name:'发布篇数',id:7}
				],
				dataInfo:{
					subscribeQuantity:0,
					unsubscribeQuantity:0,
					registerQuantity:0
				},
				pushData:{},
				timeInterval:[],
				tableData:[],
				emptyText:'暂无数据'
			}
		},
		computed:{
			// 计算出本月第一天和最后一天
			countDate () {
			  let end = new Date()
			  let start = new Date()
			  start = new Date(start.getFullYear(), start.getMonth(), 1)
			  return [
			    dateFormat(start, 'YYYY-MM-DD'),
			    dateFormat(end, 'YYYY-MM-DD')
			  ]
			}
		},
		created() {
			this.timeInterval = this.countDate
			this.search()
			this.getData()
			
		},
		methods:{
			getData(){
				spreadQrcodeDataStatistics().then(res => {
					if(res&&res.success){
						let data = res.data
						this.dataInfo = {
							subscribeQuantity:data.subscribeQuantity?data.subscribeQuantity:0,
							unsubscribeQuantity:data.unsubscribeQuantity?data.unsubscribeQuantity:0,
							registerQuantity:data.registerQuantity?data.registerQuantity:0
						}
						this.tableData = data.ranking
					}
				})
			},
			getType(type){
				this.type = type
				this.init(type)
			},
			init(type){
				// console.log(this.$refs.echartsContainer)
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(this.$refs.echartsContainer);
				var echartsData = echarts.init(this.$refs.echartsData);
				echartsData.setOption({
					color:['#00DDFF'],
					title: {
						trigger: 'axis',
						axisPointer: {
						  type: 'cross',
						  label: {
							backgroundColor: '#6a7985'
						  }
						}
					},
					tooltip: {},
					legend: {
					    data:['阅读人数']
					},
					grid: {
					    left: '3%',
					    right: '4%',
					    bottom: '3%',
					    containLabel: true
					},
					// grid: {x: 60, x2: 20},
					toolbox: {
						show: false
					},
					// calculable: true,
					xAxis: {
						show: false,
						// data:this.daysArr
						data: ['一月', '二月', '三月', '四月', '五月', '六月']
					},
					labelLine: {
						show: false,
					},
					yAxis: {
						show: false,
					},
					series: [
						{
							name:'',
							type:'line',
							smooth: true,
							lineStyle: {
								// width: 0
							},
							stack: 'Total',
							showSymbol: true,
							areaStyle: {
								opacity: 1,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									// {
									// 	offset: 1,
									// 	color: 'rgb(128, 255, 165)'
									// },
									{
										offset: 1,
										color: 'rgb(255, 255, 255)'
									},
									{
										offset: 0,
										color: 'rgb(1, 191, 236)'
									}
								])
							},
							emphasis: {
								focus: 'series'
							},
							// data:this.subscribeArr
							data:[30, 80,40, 134, 90, 230]
						}
					]
				})
				// // 绘制图表
				let dataType = []
				let series = []
				if(type == 1){
					dataType = ['阅读人数','阅读次数']
					dataType.forEach((item,index)=>{
						series.push({
							name:item,
							type:'line',
							data:index==0?[120, 132, 101, 134, 90, 230]:[5, 20, 36, 10, 10, 20]
						})
					})
					// series = [
					// 	{
					// 		name:'阅读人数',
					// 		type:'line',
					// 		// data:this.subscribeArr
					// 		data:[120, 132, 101, 134, 90, 230]
					// 	},
					// 	{
					// 		name: '阅读次数',
					// 		type: 'line',
					// 		// data:this.unsubscribeArr
					// 		data: [5, 20, 36, 10, 10, 20]
					// 	}
					// ]
				}
				if(type == 2){
					dataType = ['分享人数','分享次数']
					dataType.forEach((item,index)=>{
						series.push({
							name:item,
							type:'line',
							data:index==0?[120, 132, 101, 134, 90, 230]:[5, 15, 36, 10, 110, 80]
						})
					})
				}
				myChart.setOption({
					title: {},
					tooltip: {},
					legend: {
					    data:dataType
					},
					grid: {x: 60, x2: 20},
					toolbox: {
					  show: false,
					  feature: {
					    mark: { show: true },
					    dataView: { show: true, readOnly: false },
					    magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
					    restore: { show: true },
					    saveAsImage: { show: true }
					  }
					},
					calculable: true,
					xAxis: {
						// data:this.daysArr
						data: ['一月', '二月', '三月', '四月', '五月', '六月']
					},
					yAxis: {},
					series: series
				});
			},
			search(){
				// console.log(this.timeInterval)
				// if(!this.timeInterval){
				// 	this.timeInterval = this.countDate
				// }
				let postData = {}
				if(this.timeInterval&&this.timeInterval.length>0){
					postData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					postData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					postData.beginTime = null
					postData.endTime = null
				}
				postData = Object.assign(postData, this.pushData)
				if(!postData.state){
					delete postData.state
				}
				spreadQrcodeDataChartAnalyse(postData).then(res => {
					if(res&&res.success){
						let list = res.data
						let subscribeArr = []
						let unsubscribeArr = []
						let daysArr = []
						list.forEach(item=>{
							subscribeArr.push(item.subscribeQuantity)
							unsubscribeArr.push(item.unsubscribeQuantity)
							daysArr.push(item.date)
						})
						this.subscribeArr = subscribeArr
						this.unsubscribeArr = unsubscribeArr
						this.daysArr = daysArr
						this.init(1)
						// this.tableData = list
					}
				})
			},
			disabledDate(time) {
				let start = new Date()
				start = new Date(start.getFullYear(), start.getMonth(), 1)
				let begin = dateFormat(start, 'YYYY-MM-DD')
				let beginTime = new Date(begin+' '+'00:00:00').getTime()
				// return (time.getTime() > Date.now())||(beginTime > time.getTime())
				return time.getTime() > Date.now()
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	.marginR60{margin-right: 60px;}
	.color666{color: #666;}
	.dataType{
		padding: 4px 0;
		border-bottom: 3px solid transparent;
		box-sizing: border-box;
		font-size:14px;
		color: #333;
		margin-right: 20px;
	}
	.dataActive{
		border-bottom: 3px solid #112DF2;
		box-sizing: border-box;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>