<template>
	<div>
		<div class="cardCon flexB">
			<div></div>
			<el-button class="" type="primary" color='#112DF2' @click="add">新增</el-button>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="name" label="标签名称" />
		    <el-table-column prop="enabled" label="是否启用">
				<template #default="{row}">
					<el-switch
						@change="inOrOff(row.enabled,row.id)"
						v-model="row.enabled" 
						style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						:active-value="false" :inactive-value="true"/>
				</template>
			</el-table-column>
			<el-table-column prop="updatedTime" label="最后更新"></el-table-column>
			<el-table-column label="操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="editTag(row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
		<tag-creat :dialog-visible.sync='dialogVisible' :type='tagType' :tag-item='tagItem' :visiFlag="visiFlag"></tag-creat>
	</div>
</template>

<script>
	import tagCreat from "@/components/PopTable/tagCreat";
	import {tagPageList,tagUpdateEnabledById} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	export default {
		components:{tagCreat},
		data () {
			return {
				dialogVisible:false,
				pushData:{
					shopId:'',
					merchantId:''
				},
				tagType:'add',
				tagItem:{},
				merchantId:'',
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					name:'',
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				// postData = Object.assign(postData, this.pushData)
				tagPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.updatedTime = item.updatedTime ? formatDateOrder(item.updatedTime):formatDateOrder(item.createdTime)
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			inOrOff(enabled,id){
				let data = {
					id,
					enabled
				}
				tagUpdateEnabledById({data}).then(res=>{
					let msg = res.msg
					if(res.success){
						msg = enabled?'启用成功':'禁用成功'
						this.$message.success(msg)
					}else{
						this.$message.error(msg)
					}
					this.search()
				})
			},
			editTag(item){
				this.tagItem = item
				this.tagType = 'edit'
				this.dialogVisible = true
			},
			visiFlag(val,type){
				this.dialogVisible = val
				if(type){
					this.search()
				}
			},
			add(){
				this.tagItem = {}
				this.tagType = 'add'
				this.dialogVisible = true
				// this.$router.push('/distributionManagement/creatCode/')
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 98%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>