<template>
	<div>
		<div class="marginB20">
			<el-radio-group v-model="type" label="size control" fill='#112DF2' size="default">
				<el-radio-button label="1" @change="changeType">文章</el-radio-button>
			    <!-- <el-radio-button label="2">视频</el-radio-button> -->
			    <el-radio-button label="3" @change="changeType">草稿</el-radio-button>
			</el-radio-group>
		</div>
		<div v-if="type=='1'">
			<div class="flexB">
				<div class="flexA">
					<el-input placeholder="关键字查询" style="width: 200px;" class="marginR10" v-model="pushData.fuzzySearch" @keyup.enter="search()" />
					<el-select v-model="pushData.tagIds" multiple style="width: 200px;" placeholder="全部标签" clearable class="marginR10">
						<el-option style="max-width: 200px;" v-for="item in tagList" :key="item.id"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
					<el-select v-model="pushData.state" style="width: 200px;" @change="search()" placeholder="全部状态" clearable class="marginR10">
						<el-option style="max-width: 200px;" v-for="item in statusList" :key="item.id"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
					<el-cascader ref="cascaderAddr" :options="options" placeholder="全部分类" :props="props" clearable v-model='pushData.categoryIds' @change="handleChange" />
				</div>
				<div class="flexA">
					<el-button type="primary" color='#112DF2' @click="search()">搜索</el-button>
					<el-button type="primary" color='#112DF2' @click="add">新增</el-button>
				</div>
			</div>
			<div class="tablePage">
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
					<el-table-column prop="title" label="文章标题" />
					<el-table-column prop="categoryName" label="分类名称" />
					<el-table-column prop="url" label="列表图片">
						<template #default="{row}">
							<img class="qrcode" style="width: 60px;margin: 0 auto;border-radius: 4px;" :src="row.picUrl" alt="">
						</template>
					</el-table-column>
					<el-table-column prop="spreadUnitPrice" label="标签">
						<template #default="scope">
							<div class="tags" style="margin: 0 2px;" v-for="(item,index) in scope.row.tagsNameList" :key="index">{{item}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="publishedTime" label="发布时间" width="160">
						<template #default="scope">
							
						</template>
					</el-table-column>
				    <el-table-column prop="state" label="状态" width="80">
						<template #default="{row}">
							<div>{{row.state=='PUBLISHED'?'已发布':row.state=='CANCEL_PUBLISHED'?'取消发布':''}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="createdTime" label="操作" width="420">
						<template #default="{row}">
							<el-button link type="primary" v-if="row.state=='CANCEL_PUBLISHED'" plain color="#112DF2" @click="edit(row.id)">编辑</el-button>
							<el-button link type="primary" v-if="row.state=='CANCEL_PUBLISHED'" plain color="#112DF2" @click="delPro(row.id)">删除</el-button>
							<el-button link type="primary" v-if="row.state=='PUBLISHED'" plain color="#112DF2" @click="cancelPublished(row.id)">取消发布</el-button>
							<el-button link type="primary" v-if="row.state=='PUBLISHED'" plain color="#112DF2" @click="previewItem(row)">详情</el-button>
							<el-button link type="primary" plain color="#112DF2" @click="DataAnalysis(row.id)">内容分析</el-button>
							<el-button link type="primary" v-if="row.state=='PUBLISHED'&&!row.isTop" plain color="#112DF2" @click="UpdateIsTopById(row.id,true)">置顶</el-button>
							<el-button link type="primary" v-if="row.state=='PUBLISHED'&&row.isTop" plain color="#112DF2" @click="UpdateIsTopById(row.id,false)">取消置顶</el-button>
							<el-button link type="primary" plain color="#112DF2" @click="SettingVirtualData(row)">虚拟数据</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div v-if="type=='3'">
			<div class="flexA">
				<el-input placeholder="请输入标题/关键词" v-model="pushData.fuzzySearch" @keyup.enter="search()" class="marginR10" style="width: 200px;">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
			<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
				<el-table-column prop="title" label="名称">
					<template #default="{row}">
						<div class="flexA">
							<el-image class="img80 bgColor marginR10" :src="row.url">
								<template #error>
								  <div class="image-slot ">
									  <img class="qrcode" style="width: 80px;" :src="row.picUrl" alt="">
								  </div>
								</template>
							</el-image>
							<div class="textL overTitle">{{row.title}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="updatedTime" label="更新时间"></el-table-column>
				<el-table-column label="操作">
					<template #default="{row}">
						<el-button-group>
							<el-tooltip effect="dark" content="编辑" placement="bottom">
								<el-button type="primary" plain size='mini' :icon="Edit" @click="edit(row.id)"></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="删除" placement="bottom">
								<el-button type="primary" plain size='mini' :icon="Delete" @click="delPro(row.id)"></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="发布" placement="bottom">
								<el-button type="primary" plain size='mini' :icon="Promotion" @click="publish(row)"></el-button>
							</el-tooltip>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
		<virtual-data-setting :dialog-visible.sync='dialogVisible' :article-item='articleItem' :visiFlag="visiFlag"></virtual-data-setting>
		<article-preview :dialog-visible.sync='dialogVisiblePre' :article-item='articleDetails' :dialogVisiFlag="dialogVisiFlag"></article-preview>
	</div>
</template>

<script>
	import virtualDataSetting from "@/components/PopTable/virtualDataSetting";
	import articlePreview from "@/components/PopTable/articlePreview";
	import {articlePageList,articleUpdateIsTopById,articleCancelPublished,articleDeleteByIds,categoryTreeList,tagList,articlePublish} from '@/api'
	import {formatDateOrder,dateFormat,formatDateArtical} from '@/utils/data'
	import { Search, Delete, Edit,Promotion } from "@element-plus/icons-vue";
	export default {
		components:{virtualDataSetting,articlePreview},
		data () {
			return {
				Search,Edit,Delete,Promotion,
				pushData:{
					state:null,
					tagIds:[],
					categoryIds:[],
					fuzzySearch:''
				},
				type:'1',
				dialogVisible:false,
				dialogVisiblePre:false,
				articleItem:{},
				articleDetails:{},
				statusList:[
					{name:'已发布',id:'PUBLISHED'},
					{name:'取消发布',id:'CANCEL_PUBLISHED'}
				],
				props:{checkStrictly: true ,multiple: true},
				options:[],
				tagList:[],
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			if(this.$route.params.type){
				this.type = this.$route.params.type
			}
			this.getData()
			this.getTags()
			this.search()
		},	
		methods:{
			dialogVisiFlag(val){
				this.dialogVisiblePre = val
			},
			previewItem(item){
				item.nowTime = item.publishedTime
				this.articleDetails = item
				this.dialogVisiblePre = true
			},
			changeType(){
				this.pageNum = 1
				this.pageSize = 10
				this.total = 0
				this.tableData = []
				this.search()
			},
			SettingVirtualData(item){
				this.articleItem = item
				this.dialogVisible = true
			},
			visiFlag(val,type){
				this.dialogVisible = val
				this.search()
			},
			UpdateIsTopById(id,isTop){
				let data = { id,isTop}
				articleUpdateIsTopById({data}).then(res=>{
					if(res&&res.success){
						this.$message.success(isTop?'置顶成功！':'取消置顶成功！')
						this.search()
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			cancelPublished(id){
				this.$messageBox.confirm('是否确定取消发布？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					articleCancelPublished({data:{id}}).then(res=>{
						if(res&&res.success){
							this.$message.success(res.msg)
							this.search()
						}
					})
				}).catch(()=>{})
			},
			getTags(){
				tagList().then(res=>{
					if(res.success){
						this.tagList = res.data
					}
				})
			},
			getData(){
				categoryTreeList({data:{enabled:true}}).then(res=>{
					if(res.success){
						let data = res.data
						let arr = []
						if(data.length>0){
							data.forEach(item=>{
								item.label = item.name
								item.value = item.id
								if(item.children&&item.children.length>0){
									for (let i of item.children) {
										i.label = i.name
										i.value = i.id
									}
								}
								arr.push(item)
							})
						}
						this.options = arr
					}
				})
			},
			search(){
				let postData = {
					"paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				let pushData = JSON.parse(JSON.stringify(this.pushData))
				if(this.type==1){
					postData = Object.assign(postData, pushData)
					let categoryIds = []
					if(pushData.categoryIds.length>0){
						categoryIds = Array.from(new Set((pushData.categoryIds.join(',').split(','))))
					}
					postData.categoryIds = categoryIds
					postData.excludeStates = ['DRAFT']
					if(postData.state){
						postData.includeStates = [postData.state]
						delete postData.state
					}
				}
				if(this.type==3){
					postData.includeStates = ['DRAFT']
					postData.fuzzySearch = pushData.fuzzySearch
				}
				articlePageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							if(this.type==1){
								item.publishedTime = formatDateOrder(item.publishedTime)
								item.tagsNameList = item.tagNames&&item.tagNames.length>0?item.tagNames.split(','):[]
							}
							if(this.type==3){
								item.updatedTime = item.updatedTime ? formatDateOrder(item.updatedTime):formatDateOrder(item.createdTime)
							}
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			add(){
				this.$router.push('/knowledgeClassroom/articleCreat/')
			},
			edit(id){
				this.$router.push('/knowledgeClassroom/articleCreat/'+id)
			},
			DataAnalysis(id){
				this.$router.push('/knowledgeClassroom/contentAnalysis/'+id)
			},
			delPro(id){
				this.$messageBox.confirm('是否确定删除此条数据？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					const data = {ids:id}
					articleDeleteByIds({data}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.search()
						}else{
							this.$message.error(res.msg)
						}
					})
				}).catch(()=>{})
			},
			publish(item){
				let body = {
					title:item.title,
					categoryId:item.categoryId?item.categoryId:'',
					content:item.content?item.content:'',
					picUrl:item.picUrl,
					id:item.id,
					tagIds:item.tagIds?item.tagIds:[]
				}
				articlePublish(body).then(res=>{
					if(res.success){
						this.$message.success('发布成功！')
						this.search()
					}else{
						this.$message.error(res.extra)
					}
				})
			},
			handleChange(e) {
				var  thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()    //注意2： 获取label值
				this.pushData.categoryIds = e
				// this.search()
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.tags{
		display: inline-block;
		border: 1px solid #999;
		border-radius: 32px;
		padding: 1px 12px;
		box-sizing: border-box;
		font-size: 12px;
	}
	.overTitle{
		width:calc(100% - 90px);
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.tablePage{
		height: calc(100vh - 220px);
		overflow-y: auto;
	}
</style>