<template>
	<div class="wrap">
		<div class="card-header">
			<el-card class="box-card">
				<div class="marginB20">
					<el-input placeholder="搜索" v-model="fuzzySearch" @keyup.enter="search()">
						<template #suffix>
							<!--   vue3图标使用方式  -->
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search()"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
				<div class="newComment marginB10" :class="[isNews?'getNews':'']" @click="showNewsList(true)">最新评论</div>
				<div class="articalWrap">
					<div v-for="item in tableData" :key="item.id" @click="getArticleDetails(item.id,item.commentVolume)" class="articleItem" :class="[current==item.id ? 'current' : '']">
						<div class="flexA">
							<img class="picUrl marginR10" :src="item.picUrl" alt="" />
							<div>
								<div class="marginB10 color606">{{ item.title }}</div>	
								<div class="flexA color999 font14">
									<div class="marginR10">{{item.publishedTime}}</div>
									<el-icon><ChatSquare /></el-icon>
									<span>&nbsp;&nbsp;{{item.commentVolume}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</el-card>
			<el-card class="mainCon">
				<div v-if="isNews" class="newsCommon">
					<div class="flexS font14 commentWrap" v-for="(item,index) in list" :key="item.id">
						<img class="headImg marginR10" v-if="item.customerAvatarUrl" :src="item.customerAvatarUrl" alt="" />
						<img class="headImg marginR10" v-if="!item.customerAvatarUrl" src="@/assets/images/logo.png" alt="" />
						<div class="commentCon" :style="(index+1)==list.length?'border-bottom-color:transparent;':''">
							<div class="flexB marginB10">
								<div class="flexA">
									<div class="marginR20">{{item.customerNickName}}</div>
									<div class="color606">评论了文章《{{item.articleTitle}}》</div>
								</div>
								<div class="color606">{{item.createdTime}}</div>
							</div>
							<div class="marginB10">{{item.content}}</div>
							<div class="flexB">
								<span></span>
								<div class="flexA">
									<div class="marginR20 color909">点赞&nbsp;&nbsp;{{item.likeVolume}}</div>
									<el-button class="marginR20" link type="info" plain @click="commentTop(item.id,!item.isTop)">&nbsp;&nbsp;{{item.isTop?'取消':''}}置顶</el-button>
									<el-button class="marginR20" link type="info" v-if="item.replyVolume>0" plain @click="toReply(item,isNews,1)">
										<el-icon><ChatDotRound /></el-icon>&nbsp;&nbsp;{{item.replyVolume}}条回复
									</el-button>
									<el-button class="marginR20" link type="info" plain @click="delPro(item.id)"><el-icon><Delete /></el-icon></el-button>
									<el-button link type="info" plain @click="toReply(item,isNews)">
										<svg t="1736760084572" class="icon" viewBox="0 0 1025 1024" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path d="M415.937331 320 415.937331 96 20.001331 438.176C-6.718669 461.28-6.622669 498.784 20.033331 521.824L415.937331 864 415.937331 640C639.937331 640 847.937331 688 1023.937331 928 943.937331 480 607.937331 320 415.937331 320" p-id="4274" fill="#909399"></path></svg>
										&nbsp;&nbsp;回复
									</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!isNews&&articleDetail&&articleDetail.id">
					<div class="flexA marginB20">
						<img class="picUrlDetail marginR20" :src="articleDetail.picUrl" alt="" />
						<div>
							<div class="marginB10 color606">{{ articleDetail.title }}</div>	
							<div class="font14 color606">{{articleDetail.publishedTime}}</div>
						</div>
					</div>
					<div>
						<div class="flexB marginB10">
							<span>全部评论（{{commonTotal}}）</span>
							<div class="rankingWrap font12 flexA">
								<div :class="[rankingType=='LIKE_VOLUME'?'activeRanking color333':'color606']" @click="changeRankingType('LIKE_VOLUME')">默认</div>
								<div :class="[rankingType=='CREATED_TIME'?'activeRanking color333':'color606']" @click="changeRankingType('CREATED_TIME')">最新</div>
							</div>
						</div>
						<div class="articalCommon">
							<div class="flexS font14 commentWrap" v-for="(item,index) in list" :key="item.id">
								<img class="headImg marginR10" v-if="item.customerAvatarUrl" :src="item.customerAvatarUrl" alt="" />
								<img class="headImg marginR10" v-if="!item.customerAvatarUrl" src="@/assets/images/logo.png" alt="" />
								<div class="commentCon" :style="(index+1)==list.length?'border-bottom-color:transparent;':''">
									<div class="flexA marginB10">
										<div class="marginR20">{{item.customerNickName}}</div>
										<div class="color606">{{item.createdTime}}</div>
									</div>
									<div class="marginB10">{{item.content}}</div>
									<div class="flexB">
										<span></span>
										<div class="flexA">
											<div class="marginR20 font14 color909">点赞&nbsp;&nbsp;{{item.likeVolume}}</div>
											<el-button class="marginR20 font14" link type="info" plain @click="commentTop(item.id,!item.isTop)">&nbsp;&nbsp;{{item.isTop?'取消':''}}置顶</el-button>
											<el-button class="marginR20" v-if="item.replyVolume>0" link type="info" plain @click="toReply(item,isNews,1)">
												<el-icon><ChatDotRound /></el-icon>&nbsp;&nbsp;{{item.replyVolume}}条回复
											</el-button>
											<el-button class="marginR20" link type="info" plain @click="delPro(item.id)"><el-icon><Delete /></el-icon></el-button>
											<el-button link type="info" plain @click="toReply(item,isNews)">
												<svg t="1736760084572" class="icon" viewBox="0 0 1025 1024" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path d="M415.937331 320 415.937331 96 20.001331 438.176C-6.718669 461.28-6.622669 498.784 20.033331 521.824L415.937331 864 415.937331 640C639.937331 640 847.937331 688 1023.937331 928 943.937331 480 607.937331 320 415.937331 320" p-id="4274" fill="#909399"></path></svg>
												&nbsp;&nbsp;回复
											</el-button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="paginations" v-if="total1>0">
						<el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="pageNum1" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize1" layout="prev, pager, next" :total="total1"></el-pagination>
					</div>
				</div>
			</el-card>
		</div>
		<recover-view :dialog-visible.sync='dialogVisible' :article-item='articleItem' :visiFlag="visiFlag"></recover-view>
		<to-reply :dialog-visible.sync='dialogVisibleReply' :common-item='commonItem' :subMit="subMit"></to-reply>
	</div>
</template>

<script>
	import recoverView from "@/components/PopTable/recoverView";
	import toReply from "@/components/PopTable/toReply";
	import { Search } from "@element-plus/icons-vue";
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import {articleSimplePageList,articleGetById,articleCommentPageList,articleCommentNewestPageList,articleCommentDeleteById,articleCommentTop,articleComment} from '@/api'
	export default {
		components:{recoverView,toReply},
		data () {
			return {
				dialogVisible:false,
				dialogVisibleReply:false,
				articleItem:{},
				options:[],
				Search,
				fuzzySearch:'',
				current:'',
				rankingType:'LIKE_VOLUME',
				articleDetail:{},
				isNews:false,
				commonTotal:0,
				list:[],
				tableData:[],
				pageSize: 10,
				pageNum: 1,
				total:0,
				pageSize1: 20,
				pageNum1: 1,
				total1:0
			}
		},
		created() {
			this.search()
			this.showNewsList(true)
		},	
		methods:{
			toReply(item,isNews,type){
				// if(item.isAuthor){
				// 	this.$message.warning('作者不能回复自己的哦~')
				// 	return
				// }
				if(type){
					this.articleItem = item
					this.articleItem.isNews = isNews
					this.dialogVisible = true
					return
				}
				this.commonItem = item
				this.commonItem.isNews = isNews
				this.commonItem.isCommonReply = true
				this.dialogVisibleReply = true
			},
			delPro(id){
				this.$messageBox.confirm('是否确定删除此条评论？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					const data = {id}
					articleCommentDeleteById({data}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.getList(this.articleDetail.id)
						}else{
							this.$message.error(res.msg)
						}
					})
				}).catch(()=>{})
			},
			commentTop(id,isTop){
				let data = {
					id,isTop
				}
				articleCommentTop({data}).then(res=>{
					if(res&&res.success){
						this.$message.success(isTop?'置顶成功！':'取消置顶成功！')
						if(this.isNews){
							this.showNewsList(true)
							return
						}
						this.pageNum1 = 1
						this.pageSize1 = 20
						this.total1 = 0
						this.getList(this.articleDetail.id)
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			changeRankingType(val){
				this.rankingType = val
				this.pageNum1 = 1
				this.pageSize1 = 20
				this.total1 = 0
				this.getList(this.articleDetail.id)
			},
			showNewsList(isNews){
				this.isNews = isNews
				this.current = ''
				this.pageNum1 = 1
				this.pageSize1 = 20
				this.total1 = 0
				this.articleDetail = {}
				articleCommentNewestPageList({}).then(res=>{
					if(res&&res.success){
						let list = res.data
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
						})
						this.list = list
					}
				})
			},
			visiFlag(val,type){
				this.dialogVisible = val
				if(type){
					this.showNewsList(true)
				}else{
					this.getList(this.articleDetail.id)
				}
			},
			subMit(val,type){
				this.pageNum = 1
				this.dialogVisibleReply = val
				if(type){
					this.showNewsList(true)
				}else{
					this.getList(this.articleDetail.id)
				}
			},
			getArticleDetails(id,num){
				this.list = []
				this.current = id
				this.isNews = false
				articleGetById({data:{id}}).then(res=>{
					if(res&&res.success){
						let data = res.data
						data.publishedTime = formatDateOrder(data.publishedTime)
						this.articleDetail = data
						this.pageNum1 = 1
						this.pageSize1 = 20
						this.total1 = 0
						this.commonTotal = num
						this.getList(data.id)
						// this.articleComment(data.id)
					}
				})
			},
			getList(articleId){
				let body = {
					articleId,
					ranking:this.rankingType,
					"paging": {
						"pageNum": this.pageNum1,
						"pageSize": this.pageSize1,
					}
				}
				articleCommentPageList(body).then(res=>{
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
							item.customerNickName = item.isAuthor?'拾悦纪':item.customerNickName
						})
						this.list = list
						this.total1 = Number(res.data.total)
					}
				})
			},
			articleComment(articleId){
				let data = {
					articleId,
					content:'这是要删除的评论'
				}
				articleComment({data}).then(res=>{
					
				})
			},
			search(){
				this.isNews = false
				let postData = {
					fuzzySearch:this.fuzzySearch,
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				articleSimplePageList(postData).then(res=>{
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.publishedTime = formatDateOrder(item.publishedTime)
						})
						this.tableData = list
						this.total = Number(res.data.total)
						// if(this.pageNum == 1){
						// 	this.current = list[0].id
						// 	this.getArticleDetails(list[0].id)
						// }
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
			handleSizeChange1(val) {
				this.pageSize1 = val;
				this.getList(this.articleDetail.id)
			},
			handleCurrentChange1(val) {
				this.pageNum1 = val;
				this.getList(this.articleDetail.id)
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.box-card{
		width: 380px;
		height: calc(100vh - 60px);
	}
	.card-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}
	.articalWrap{
		height:calc(100vh - 300px);
		overflow-y: auto;
	}
	.articalCommon{
		height: calc(100vh - 380px);
		overflow-y: auto;
	}
	.mainCon{
		width: calc(100% - 380px);
		height: calc(100vh - 60px);
	}
	.newsCommon{
		height: calc(100vh - 100px);
		overflow-y: auto;
	}
	.paginations{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.articleItem{
		padding: 10px 5px;
		box-sizing: border-box;
	}
	.current{
		color: #fff;
		background:lightyellow;
		border-radius: 10px;
	}
	.picUrl{
		width: 50px;
		height: 50px;
	}
	.picUrlDetail{
		width: 80px;
		height: 80px;
	}
	.newComment{
		padding:15px 10px;
		box-sizing: border-box;
		background-color: #f7f7f7;
	}
	.getNews{
		background-color: #409eff;
		color: #fff;
	}
	.headImg{
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
	.commentWrap{
		padding-top: 10px;
	}
	.commentCon{
		width: calc(100% - 60px);
		padding: 10px;
		box-sizing: border-box;
		border-bottom: 2px solid #f9f9f9;
	}
	.rankingWrap{
		width: 120px;
		height: 30px;
		line-height: 26px;
		border-radius: 30px;
		padding: 2px;
		box-sizing: border-box;
		background-color: #dcdfe6;
	}
	.rankingWrap div{
		width: 50%;
		text-align: center;
		height: 100%;
		border-radius: 30px;
	}
	.rankingWrap .activeRanking{
		background-color: #fff;
	}
</style>