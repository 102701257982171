<template>
	<div>
		<div class="cardCon flexB">
			<div class="flexA">
				<el-select v-model="pushData.enabled" @change="search()" placeholder="全部状态" clearable class="marginR10">
					<el-option v-for="item in statusList" :key="item.status"
						:label='item.name' :value="item.status"></el-option>
				</el-select>
			</div>
			<el-button class="" type="primary" color='#112DF2' @click="add()">新增分类</el-button>
		</div>
		<el-table :data="tableData" row-key="id" :tree-props="{children:'children',hasChildren:'hasChildren'}" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="" label="">
				<template #default="{row}">
					<!-- <el-icon v-if="!row.parentId"><ArrowUp /></el-icon>
					<el-icon v-if="row.parentId"><ArrowDown /></el-icon> -->
				</template>
			</el-table-column>
			<el-table-column prop="parentId" label="层级">
				<template #default="{row}">
					{{row.parentId?'二级分类':'一级分类'}}
				</template>
			</el-table-column>
			<el-table-column prop="name" label="分类名称" />
		    <el-table-column prop="enabled" label="是否启用">
				<template #default="{row}">
					<el-switch
						@change="inOrOff(row.enabled,row.id)"
						v-model="row.enabled" 
						style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						:active-value="false" :inactive-value="true"/>
				</template>
			</el-table-column>
			<el-table-column prop="relationQuantity" label="当前内容数"></el-table-column>
			<el-table-column prop="updatedTime" label="最后更新"></el-table-column>
			<el-table-column label="操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="editTag(row)">编辑</el-button>
					<el-button v-if="!row.parentId" link type="primary" plain color="#112DF2" @click="add(row.id)">新增</el-button>
				</template>
			</el-table-column>
		</el-table>
		<classify-creat :dialog-visible.sync='dialogVisible' :type='tagType' :tag-item='tagItem' :visiFlag="visiFlag"></classify-creat>
	</div>
</template>

<script>
	import classifyCreat from "@/components/PopTable/classifyCreat";
	import {categoryTreeList,categoryUpdateEnabledById} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	export default {
		components:{classifyCreat},
		data () {
			return {
				dialogVisible:false,
				pushData:{
					enabled:''
				},
				classType:'1',
				tagType:'add',
				statusList:[
					{name:'全部',status:''},
					{name:'启用',status:'true'},
					{name:'禁用',status:'false'}
				],
				tagItem:{},
				merchantId:'',
				tableData:[],
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			search(){
				let data = this.pushData
				if(data.enabled == ''){
					data.enabled = null
				}
				categoryTreeList({data}).then(res => {
					if(res&&res.success){
						let list = res.data
						list.forEach(item=>{
							item.updatedTime = item.updatedTime ? formatDateOrder(item.updatedTime):formatDateOrder(item.createdTime)
							if(item.children&&item.children.length>0){
								for(let items of item.children){
									items.updatedTime = items.updatedTime ? formatDateOrder(items.updatedTime):formatDateOrder(items.createdTime)
								}
							}{
								
							}
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			inOrOff(enabled,id){
				let data = {
					id,
					enabled
				}
				categoryUpdateEnabledById({data}).then(res=>{
					let msg = res.msg
					if(res.success){
						msg = enabled?'启用成功':'禁用成功'
						this.$message.success(msg)
					}else{
						this.$message.error(msg)
					}
					this.search()
				})
			},
			editTag(item){
				this.tagItem = item
				this.tagType = 'edit'
				this.dialogVisible = true
			},
			visiFlag(val,type){
				this.dialogVisible = val
				if(type){
					this.search()
				}
			},
			add(id){
				this.tagItem = {}
				if(id){
					this.tagItem.parentId = id
					this.tagItem.enabled = true
				}
				this.tagType = 'add'
				this.dialogVisible = true
				// this.$router.push('/distributionManagement/creatCode/')
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 98%;
		margin-bottom: 10px;
	}
	:deep(.el-table__expand-icon>.el-icon){
		font-size: 20px;
	}
</style>