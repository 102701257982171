<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="" :model-value="dialogVisible" align-center width="800px" style="height: 90vh;" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<div class="conmmonMainWrap marginB20">
					<div class="flexA conmmonMain">
						<img class="headImg marginR20" v-if="articleItem.customerAvatarUrl" :src="articleItem.customerAvatarUrl" alt="" />
						<img class="headImg marginR20" v-if="!articleItem.customerAvatarUrl" src="@/assets/images/logo.png" alt="" />
						<div>
							<div class="flexA marginB20">
								<div class="marginR20 color606">{{ articleItem.customerNickName }}</div>
								<div class="font14 color606">{{articleItem.createdTime}}</div>
							</div>
							<div>{{articleItem.content}}</div>
						</div>
					</div>
					<el-button class="font14 flexA" link type="info" plain @click="toReply(articleItem,1)">
						<svg t="1736760084572" class="icon" viewBox="0 0 1025 1024" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path d="M415.937331 320 415.937331 96 20.001331 438.176C-6.718669 461.28-6.622669 498.784 20.033331 521.824L415.937331 864 415.937331 640C639.937331 640 847.937331 688 1023.937331 928 943.937331 480 607.937331 320 415.937331 320" p-id="4274" fill="#909399"></path></svg>
						&nbsp;&nbsp;回复
					</el-button>
				</div>
				<div>
					<div class="flexB marginB10">
						<span>全部回复&nbsp;&nbsp;{{total}}</span>
						<!-- <div class="font14 flexA" @click="toReply(item)">
							<el-icon><ChatDotRound /></el-icon>&nbsp;&nbsp;回复
						</div> -->
					</div>
					<div class="commentWrap">
						<div class="flexS font14" v-for="item in list" :key="item.id">
							<img class="headImg marginR10" v-if="item.customerAvatarUrl" :src="item.customerAvatarUrl" alt="" />
							<img class="headImg marginR10" v-if="!item.customerAvatarUrl" src="@/assets/images/logo.png" alt="" />
							<div class="commentCon">
								<div class="flexA marginB10">
									<div class="marginR20 flexA">
										{{item.isAuthor?'拾悦纪':item.customerNickName}}
										<div v-if="item.isAuthor" class="isAuthor marginL10 marginR20">作者</div>
										<div v-if="item.parentReplyId" class="flexA">
											<div class="marginR20">回复</div>
											<div>{{item.isReplyAuthor?'拾悦纪':item.replyCustomerNickName}}</div>
										</div>
										<div v-if="item.isReplyAuthor" class="isAuthor marginL10">作者</div>
									</div>
									<div class="color606">{{item.createdTime}}</div>
								</div>
								<div class="marginB10">{{item.content}}</div>
								<div class="flexB">
									<span></span>
									<div class="flexA">
										<div class="marginR20 font14 color909">点赞&nbsp;&nbsp;{{item.likeVolume}}</div>
										<!-- <el-button class="marginR20" link type="info" plain @click="toReply(item)">
											<el-icon><ChatDotRound /></el-icon>&nbsp;&nbsp;<span v-if="item.replyVolume>0">{{item.replyVolume}}条</span>回复
										</el-button> -->
										<el-button class="marginR20" link type="info" plain @click="delPro(item.id)"><el-icon><Delete /></el-icon></el-button>
										<el-button link type="info" v-if="!item.isAuthor" plain @click="toReply(item)">
											<svg t="1736760084572" class="icon" viewBox="0 0 1025 1024" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path d="M415.937331 320 415.937331 96 20.001331 438.176C-6.718669 461.28-6.622669 498.784 20.033331 521.824L415.937331 864 415.937331 640C639.937331 640 847.937331 688 1023.937331 928 943.937331 480 607.937331 320 415.937331 320" p-id="4274" fill="#909399"></path></svg>
											&nbsp;&nbsp;回复
										</el-button>
									</div>
								</div>
							</div>
						</div>						
					</div>
				</div>
			</div>
			<template #footer>
				<div class="paginations dialog-footer">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
					</el-pagination>
				</div>
			</template>
		</el-dialog>
		<to-reply :dialog-visible.sync='dialogVisibleReply' :common-item='commonItem' :subMit="subMit"></to-reply>
	</div>
</template>
<script>
	import toReply from "@/components/PopTable/toReply";
	import Validate from '@/utils/rules'
	import {formatDateOrder} from '@/utils/data'
	import {articleCommentReplyPageList,articleCommentReplyDeleteById} from '@/api'
	export default {
		components:{toReply},
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			articleItem:{
				type:Object,
				default:{}
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				dialogVisibleReply:false,
				isRefresh:false,
				pageSize: 20,
				pageNum: 1,
				total:0,
				list:[]
			}
		},
		watch: {
			dialogVisible(v) {
				if (v) {
					this.getList(this.articleItem.id)
				}
			}
		},
		methods: {
			toReply(item,type){
				this.commonItem = item
				this.commonItem.isCommonReply = type?true:false
				this.dialogVisibleReply = true
			},
			subMit(){
				this.dialogVisibleReply = false
				this.pageNum = 1
				this.getList(this.articleItem.id)
			},
			delPro(id){
				this.$messageBox.confirm('是否确定删除此条回复？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					const data = {id}
					articleCommentReplyDeleteById({data}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.getList(this.articleItem.id)
						}else{
							this.$message.error(res.msg)
						}
					})
				}).catch(()=>{})
			},
			getList(commentId){
				let body = {
					commentId,
					"paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				articleCommentReplyPageList(body).then(res=>{
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
						})
						this.list = list
						this.total = Number(res.data.total)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList(this.articleItem.id)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.getList(this.articleItem.id)
			},
			hideDialog(){
				this.visiFlag(false,this.articleItem.isNews)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.el-dialog__body{
		overflow: autp;
		// overflow-y:auto;
	}
	.conmmonMainWrap{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding-bottom: 10px;
		border-bottom: 2px solid #f9f9f9;
	}
	.conmmonMain{
		width: calc(100% - 100px);
	}
	.headImg{
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
	.commentWrap{
		padding-top: 10px;
		height: 50vh;
		// overflow-y: scroll;
	}
	.commentCon{
		width: calc(100% - 60px);
		padding: 10px;
		box-sizing: border-box;
		border-bottom: 2px solid #f9f9f9;
	}
	.isAuthor{
		color: #999999;
		font-size: 12px;
		border: 1px solid #d3d3d3;
		padding: 0 4px;
		display: inline-block;
	}
</style>
