<template>
	<div class="el-dialog-body-spacing">
		<el-dialog :title="type=='add'?'新增员工':type=='edit'?'编辑员工':''" :model-value="dialogVisible" width="600px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<el-form :model="pushData" :rules="rules" ref="formBox" label-width="120px">
					<el-form-item label="员工姓名" prop="name">
						<el-input v-model="pushData.name" placeholder="请输入员工姓名" maxlength="6" style="width: 85%" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="员工花名">
						<el-input v-model="pushData.alias" placeholder="请输入员工花名" maxlength="6" style="width: 85%" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="手机号" prop="phoneNumber">
						<el-input v-model="pushData.phoneNumber" :disabled="type=='edit'" placeholder="请输入手机号" style="width: 85%"></el-input>
					</el-form-item>
					<el-form-item label="性别" prop="sex">
						<el-radio-group class="marginR20" v-model="pushData.sex" @change="getSex">
							<el-radio label="WOMAN">女</el-radio>
							<el-radio label="MAN">男</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import Validate from '@/utils/rules'
	import {employeePsCreate,employeePsUpdateById,employeeGetById} from '@/api'
	export default {
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			recordObject:{
				type:Object,
				default:{}
			},
			type:{
				type:String,
				default: 'add'
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				pushData:{
					name:'',
					sex:'WOMAN',
					alias:'',
					phoneNumber:''
				},
				rules: {
					name: [{required: true,message: "请输入名称",trigger: "blur"}],
					sex:[{required: true}],
					phoneNumber: [{required: true,message: "请输入正确手机号",validator: Validate.isTel, trigger: "blur"}]
				},
				isRefresh:false
			}
		},
		watch: {
			dialogVisible(v) {
				if (v) {
					if(this.type == 'edit'){
						this.pushData.id = ''
						if (Object.keys(this.recordObject).length) {
							for (let key in this.pushData) {
								this.pushData[key] = this.recordObject[key]
							}
						}
					}else{
						this.pushData = {
							name:'',
							sex:'WOMAN',
							alias:'',
							phoneNumber:''
						}
					}
				}
			}
		},
		methods: {
			confirm(){
				this.$refs.formBox.validate((valid)=>{
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.pushData))
						if(this.type == 'edit'){
							data.state = 'ON_JOB'
							delete data.phoneNumber
						}
						let postUrl = this.type == 'edit' ? employeePsUpdateById : employeePsCreate
						postUrl(data).then(res=>{
							if(res.success){
								this.isRefresh = true
								this.hideDialog()
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			hideDialog() {
				this.visiFlag(false, this.isRefresh)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}	
</style>
