<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="" :model-value="dialogVisible" width="800px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<div class="flexA">
					<img class="headImg marginR10" src="@/assets/images/logo.png" alt="" />
					<div class="flexA">
						<div class="marginR20">拾悦纪</div>
						<div class="marginR20">回复</div>
						<span>{{commonItem.customerNickName}}</span>
					</div>
				</div>
				<el-form :model="pushData" :rules="rules" ref="formBox" label-width="60px">
					<el-form-item label="" prop="content">
						<el-input v-model="pushData.content" maxlength="500" resize="none" rows="12" clearable style="width: 90%" placeholder="发布回复" show-word-limit type="textarea" />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">回  复</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import Validate from '@/utils/rules'
	import {replyCommentReply,replyComment} from '@/api'
	export default {
		props: {
			subMit: {
				type: Function,
				default: function() {}
			},
			commonItem:{
				type:Object,
				default:{}
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				pushData:{
					content:''
				},
				rules: {
					content: [{required: true,trigger: "blur"}]
				},
				isRefresh:false
			}
		},
		watch: {
			dialogVisible(v) {
				this.pushData = {
					content:''
				}
				if (v) {
					
				}
			}
		},
		methods: {
			confirm(){
				this.$refs.formBox.validate((valid)=>{
					if (valid) {
						let data = {
							content:this.pushData.content
						}
						if(this.commonItem.isCommonReply){
							data.commentId = this.commonItem.id
						}else{
							data.id = this.commonItem.id
						}
						let postUrl = this.commonItem.isCommonReply?replyComment:replyCommentReply
						postUrl({data}).then(res=>{	
							if(res.success){
								this.$message.success(res.msg)
								this.isRefresh = true
								this.hideDialog()
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			hideDialog() {
				let type = this.commonItem.isCommonReply?this.commonItem.isNews:''
				console.log(type)
				this.subMit(false, type)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}
	.headImg{
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
</style>
