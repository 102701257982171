<template>
	<div>
		<div class="font14">
			<el-row>
				<el-col :span="6" v-for="(item, index) in textData" :key="index">
					<el-card class="cardCon">
						{{item.text}}{{item.val}}{{item.unitCon}}
					    <el-tooltip effect="dark" :content="item.tip" v-if="!item.isVisited" placement="bottom">
							<el-icon><QuestionFilled /></el-icon>
					    </el-tooltip>
					</el-card>
				</el-col>
			</el-row>	
		</div>
		<div class="flexB">
			<div class="flexA marginR10">
				<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" value-format="YYYY-MM-DD" start-placeholder="开始日期"
					end-placeholder="结束日期" />
				<el-select v-model="pushData.belongMerchantId" style="width: 260px;" @change="search()" placeholder="商户" clearable class="marginR10">
					<el-option style="max-width: 260px;" v-for="item in merchantList" :key="item.id"
						:label='item.name' :value="item.id"></el-option>
				</el-select>
				<el-select v-model="pushData.undertakingMerchantId" style="width: 260px;" @change="search()" placeholder="承接商户" clearable class="marginR10">
					<el-option style="max-width: 260px;" v-for="item in merchantList" :key="item.id"
						:label='item.name' :value="item.id"></el-option>
				</el-select>
				<el-input placeholder="搜索会员名称手机号" v-model="pushData.fuzzySearch" @keyup.enter="search()" style="width: 200px;">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
			<div>
				<el-dropdown>
					<el-button type="primary" plain color="#112DF2">
					    更多操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
					</el-button>
				    <template #dropdown>
				      <el-dropdown-menu>
				        <el-dropdown-item @click.native="importData">批量导出</el-dropdown-item>
				      </el-dropdown-menu>
					  <el-dropdown-menu>
					    <el-dropdown-item @click.native="settleAccounts">结算</el-dropdown-item>
					  </el-dropdown-menu>
				    </template>
				  </el-dropdown>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" @selection-change="selectList" style="width: 100%;margin-top: 30px;">
			<el-table-column type="selection" fixed  width="50" />
			<el-table-column prop="belongMerchantName" fixed label="会员所属商户" width="180" />
			<el-table-column prop="customerNickName" fixed label="会员名称" width="90" />
			<el-table-column prop="customerPhoneNumber" fixed label="联系方式" width="120" />
			<el-table-column prop="orderId" fixed label="订单编号" width="200" />
			<el-table-column prop="goodsName" fixed label="商品名称" width="150" />
			<el-table-column prop="orderAmount" label="订单金额" width="120" />
			<el-table-column prop="undertakingMerchantName" label="承接商户" width="180">
				<template #default="scope">
					<div v-if="!scope.row.isEdit" class="flexAR">{{scope.row.undertakingMerchantId?scope.row.undertakingMerchantName:''}}
						<el-icon @click="isEditChange(scope.row.id)" :size="18" style="margin-left: 6px;"><Edit /></el-icon>
					</div>
					<div v-if="scope.row.isEdit">
						<el-select v-model="scope.row.undertakingMerchantId" @blur="closeOptions()" @change="undertakingMerchantIdChange(scope.row.id,scope.row.undertakingMerchantId,scope.row.belongMerchantId)" placeholder="商户" class="marginR10">
							<el-option v-for="item in merchantList" :key="item.id"
								:label='item.name' :value="item.id"></el-option>
						</el-select>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="undertakingMerchantServiceRatio" label="承接费比例" width="100" />
			<el-table-column prop="undertakingMerchantServiceAmount" label="承接收入" width="100" />
			<el-table-column prop="undertakingMerchantGuestChargeAmount" label="客资费（元）" width="180">
				<template #default="scope">
					<div v-if="!scope.row.isEditAmount" class="flexAR">{{scope.row.undertakingMerchantGuestChargeAmount?scope.row.undertakingMerchantGuestChargeAmount:0}}
						<el-icon @click="editItem(scope.row.id,scope.row.undertakingMerchantGuestChargeAmount,1)" :size="18" style="margin-left: 6px;"><Edit /></el-icon>
					</div>
					<div v-if="scope.row.isEditAmount">
						<el-input style="width:50%;" v-model="scope.row.undertakingMerchantGuestChargeAmountUp" @keyup="scope.row.undertakingMerchantGuestChargeAmountUp=scope.row.undertakingMerchantGuestChargeAmountUp.replace(/[^\d\.]/g,'')"
						 @input="groupPrice(scope.$index,scope.row.undertakingMerchantGuestChargeAmountUp)" @blur="groupPriceBlur(scope.$index,scope.row.undertakingMerchantGuestChargeAmountUp)"></el-input>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(scope.row.id,scope.row.undertakingMerchantGuestChargeAmount,3)">取消</el-button>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(scope.row.id,scope.row.undertakingMerchantGuestChargeAmountUp,2)">保存</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="belongMerchantGuestChargeRatio" label="客资费比例" width="120" />
			<el-table-column prop="belongMerchantGuestChargeAmount" label="客资收入" width="90" />
			<el-table-column prop="platformIncome" label="平台实收" width="120">
				<template v-slot:header="scope">
					<span>平台实收
						<el-tooltip effect="dark" content="（订单实付金额-承接商户承接费）+（承接方客资费-会员所属商户客资费）" placement="top">
							<el-icon><QuestionFilled /></el-icon>
						</el-tooltip>
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="orderTime" label="订单时间" width="180" />
		</el-table>
		<div class="relativeB">
			<div class="paginations">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="" width="800" :model-value="dialogVisible">
			<el-form :model="dataForm" ref="formBox" :rules="rules" label-width="180px" class="formBox">
				<el-form-item label="单据名称" prop="name">
					<el-input v-model="dataForm.name" style="max-width: 500px;"></el-input>
				</el-form-item>
				<el-form-item label="结算金额" prop="settledAmount">
					<el-input v-model="dataForm.settledAmount" style="max-width: 500px;"></el-input>
				</el-form-item>
				<el-form-item label="结算凭证">
					<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="activityUploadUrl" :file-list="fileListData" accept="image/jpg,image/png" list-type="picture-card" :limit="9" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
						<el-icon><Plus /></el-icon>
					</el-upload>
					<div class="tips">
						<div>注意：</div>
						<div>1、建议格式：jpg、png</div>
					</div>
					<el-dialog v-model="dialogVisibleImg">
					    <img w-full :src="dialogImageUrl" alt="Preview Image" />
					</el-dialog>
				</el-form-item>
			</el-form>
			<div class="flexAR">
				<el-button type="primary" color="#787878" @click="() => dialogVisible=false">取消</el-button>
				<el-button class="marginR10" type="primary" color="#112DF2" @click="save">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {merchantSpreadParticularPageList,activityUploadUrl,userLogin,merchantSpreadSettlementCreate,merchantSpreadParticularUpdateUndertakingMerchantById,merchantSpreadParticularUpdateUndertakingMerchantGuestRechargeAmountById,merchantList,merchantSpreadParticularExport} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				textData:[
					{text:'推广总人数',tip:'推广码扫码总人数',val:'0',unitCon:'人',isVisited:false},
					{text:'订单总金额',tip:'订单实付未分佣的总金额',val:'0',unitCon:'元',isVisited:false},
					{text:'订单总实收',tip:'订单实付-承接费=订单总实收',val:'0',unitCon:'元',isVisited:false},
					{text:'客资费总额',tip:'客资费用未分佣金额',val:'0',unitCon:'元',isVisited:false},
					{text:'客资费总实收',tip:'承接方客资费-会员所属商户客资费=客资费总实收',val:'0',unitCon:'元',isVisited:false},
					{text:'总收入',tip:'订单实付金额+客资金额=总收入',val:'0',unitCon:'元',isVisited:false},
					{text:'总实收收入',tip:'（订单实付金额-承接商户承接费）+（承接方客资费-会员所属商户客资费）',val:'0',unitCon:'元',isVisited:false},
					{text:'总支出',tip:'关注量人数总费用',unitCon:'元',val:'0',isVisited:false},
					{text:'实收统计',tip:'总实收收入-关注人总数支出=实收统计',val:'0',unitCon:'元',isVisited:false},
					{text:'承接费总额',tip:'',unitCon:'元',val:'0',isVisited:true}
				],
				Search,
				activityUploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'marketing/merchant-spread-receipt-certification/'},
				fileListData:[],
				imgUrl:[],
				dialogVisible:false,
				dialogVisibleImg:false,
				dialogImageUrl:'',
				dataForm:{
					name:'',
					settledAmount:'',
				},
				pushData:{
					fuzzySearch:'',
					beginTime:null,
					endTime:null,
					belongMerchantId:'',
					undertakingMerchantId:''
				},
				timeInterval:[],
				tableData:[],
				merchantList:[],
				getSelectList:[],
				rules: {
					name: [{required: true, message: '请输入单据名称', trigger: 'blur'}],
					settledAmount:[{required: true,message: '请输入结算金额',validator: Validate.isNumber, trigger: 'blur'}]
				},
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		computed:{
			userInfo() {
				return this.$store.state.userInfo;
			},
			// 计算出本月第一天和最后一天
			countDate () {
			  let end = new Date()
			  let start = new Date()
			  start = new Date(start.getFullYear(), start.getMonth(), 1)
			  return [
			    dateFormat(start, 'YYYY-MM-DD'),
			    dateFormat(end, 'YYYY-MM-DD')
			  ]
			}
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			this.timeInterval = this.countDate
			this.getMerchantList()
			this.search()
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
		},	
		methods:{
			search(){
				if(this.timeInterval&&this.timeInterval.length>0){
					this.pushData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					this.pushData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					this.pushData.beginTime = null
					this.pushData.endTime = null
				}
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				merchantSpreadParticularPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.pagination.list
						list.forEach(item=>{
							item.orderTime = formatDateOrder(item.orderTime)
							item.belongMerchantGuestChargeRatio = item.belongMerchantGuestChargeRatio*100+'%'
							item.undertakingMerchantServiceRatio = item.undertakingMerchantServiceRatio*100+'%'
							item.isEditAmount = false
							item.isEdit = false
							item.undertakingMerchantGuestChargeAmountUp = ''
						})
						this.tableData = list
						this.total = Number(res.data.pagination.total)
						let data = res.data.statistics
						this.textData[0].val = data.spreadCount
						this.textData[1].val = data.orderAmount
						this.textData[2].val = data.platformOrderActualIncome
						this.textData[3].val = data.undertakingMerchantGuestChargeAmount
						this.textData[4].val = data.platformGuestChargeActualIncome
						this.textData[5].val = data.platformGeneralIncome
						this.textData[6].val = data.platformActualIncome
						this.textData[7].val = data.platformExpenditure
						this.textData[8].val = data.platformActualIncomeStatistics
						this.textData[9].val = data.undertakingMerchantServiceAmount
					}
				})
			},
			undertakingMerchantIdChange(id,undertakingMerchantId,belongMerchantId){
				let body = {
					id,undertakingMerchantId
				}
				merchantSpreadParticularUpdateUndertakingMerchantById({data:body}).then(res=>{
					this.tableData.forEach(item=>{
						if(item.id == id){
							item.isEdit = false
						}
					})
					this.search()
					if(res&&res.success){
						return
					}
					this.$message.error(res.msg)
				})
			},
			closeOptions(id,undertakingMerchantId,belongMerchantId){
				// if(undertakingMerchantId==belongMerchantId){
				// 	return
				// }
				// this.tableData.forEach(item=>{
				// 	if(item.id == id){
				// 		item.isEdit = false
				// 	}
				// })
				// this.search()
			},
			isEditChange(id){
				this.tableData.forEach(item=>{
					if(item.id == id){
						item.isEdit = !item.isEdit
					}
				})
			},
			editItem(id,inputValue,num,type){
				this.tableData.forEach(item=>{
					if(item.id == id){
						item.isEditAmount = !item.isEditAmount
						item.undertakingMerchantGuestChargeAmountUp = inputValue
					}
				})
				if(num==2){
					let body = {
						id,undertakingMerchantGuestRechargeAmount:inputValue
					}
					merchantSpreadParticularUpdateUndertakingMerchantGuestRechargeAmountById({data:body}).then(res=>{
						this.search()
						if(res&&res.success){
							return
						}
						this.$message.error(res.msg)
						
					})
				}
			},
			groupPrice(index,val){
				if(val>99999.99)val=99999.99;
				if(val<0)val=0;
				this.tableData[index].undertakingMerchantGuestChargeAmount = val
			},
			groupPriceBlur(index,val){
				val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
				this.tableData[index].undertakingMerchantGuestChargeAmount = val
			},
			selectList (val) {
				this.getSelectList = val
			},
			settleAccounts(){
				this.dialogVisible = true
			},
			save(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						if(this.imgUrl.length==0){
							this.$message.error('至少上传一张图片')
							return
						}
						let postData = {}
						let getSelectList = this.getSelectList
						let settlementType = 'RESULT_SEARCH_SETTLE'
						let merchantSpreadParticularIds = []
						if(getSelectList.length>0){
							settlementType = 'RECORD_SELECTED_SETTLE'
							getSelectList.forEach(item=>{
								merchantSpreadParticularIds.push(item.id)
							})
						}
						let body = {
							settlementType,
							searchCondition:Object.assign(postData, this.pushData),
							name:this.dataForm.name,
							settledAmount:this.dataForm.settledAmount,
							certification:this.imgUrl.join(',')
						}
						if(merchantSpreadParticularIds.length>0){
							body.merchantSpreadParticularIds = merchantSpreadParticularIds
						}
						merchantSpreadSettlementCreate(body).then(res=>{
							if(res&&res.success){
								this.$message({
									showClose: true,
									message: "成功",
									type: "success",
								});
								this.dialogVisible = false
								this.search()
							}else{
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			importData(){//导出
				let postData = {}
				let posts = Object.assign(postData, this.pushData)
				this.$http.post(merchantSpreadParticularExport, posts, {
					headers:{
						'Authorization': this.$store.state.userInfo.token?('Bearer'+' '+ this.$store.state.userInfo.token):''
					},
					responseType: "blob"
				}).then(res => {
					const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
					const fileName = '推广统计表.xlsx';
					if ('download' in document.createElement('a')) {
						const elink = document.createElement('a');
						elink.download = fileName;
						elink.style.display = 'none';
						elink.href = URL.createObjectURL(blob);
						document.body.appendChild(elink);
						elink.click();
						URL.revokeObjectURL(elink.href);
						document.body.removeChild(elink);
					}else{
						navigator.msSaveBlob(blob, fileName);
					}
				});
			},
			getMerchantList(){
				let postData = {enabled:true}
				merchantList(postData).then(res => {
					if(res&&res.success){
						this.merchantList = res.data
					}
				})
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || (file.response?ies.url==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || (file.response?ies==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || (file.response?ies==file.response.data[0]:'')){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 9 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2 && !extension3) {
					this.$message({
						message: "上传模板只能是 .jpg,.png 格式!",
						type: "error"
					});
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2M!");
				}
				return (extension || extension1 || extension2 || extension3) && isLt2M;
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>