<template>
	<div>
		<div class="marginB20">
			<h3>广告位管理</h3>
		</div>
		<div>
			<div class="flexB" style="width: 98%;">
				<el-input placeholder="关键字查询" v-model="pushData.fuzzySearch" @keyup.enter="search()" class="marginR10" style="width: 200px;">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
				<el-button type="primary" color='#112DF2' @click="add">+ 新增</el-button>
			</div>
			<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
				<el-table-column prop="merchantName" label="广告名称" />
				<el-table-column prop="shopName" label="分类名称" />
				<el-table-column prop="url" label="背景图片">
					<template #default="{row}">
						<img class="qrcode" style="width: 60px;margin: 0 auto;" :src="row.url" alt="">
					</template>
				</el-table-column>
				<el-table-column prop="url" label="二维码">
					<template #default="{row}">
						<img class="qrcode" style="width: 60px;margin: 0 auto;" :src="row.url" alt="">
					</template>
				</el-table-column>
				<el-table-column prop="createdTime" label="发布时间">
					<template #default="scope">
						
					</template>
				</el-table-column>
			    <el-table-column prop="enabled" label="状态">
					<template #default="{row}">
						<div>{{row.enabled?'正常':'关闭'}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="createdTime" label="操作">
					<template #default="scope">
						<!-- <el-button v-if="scope.row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(scope.row.id,0)">关闭</el-button>
						<el-button v-if="!scope.row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(scope.row.id,1)">正常</el-button> -->
						<el-button link type="primary" plain color="#112DF2" @click="delPro(scope.row.id)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
		<el-dialog title="新增/编辑" width="800" :model-value="dialogVisible">
			<el-form :model="dataForm" ref="formBox" :rules="rules" label-width="180px" class="formBox">
				<el-form-item label="广告标题：" prop="shopId">
					<el-input style="max-width: 500px;" maxlength="15" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="广告副标题：" prop="shopId">
					<el-input style="max-width: 500px;" maxlength="15" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="广告内容：" prop="shopId">
					<el-input style="max-width: 500px;" maxlength="30" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="适用分类：" prop="shopId">
					<el-select style="width: 500px;" placeholder="适用分类：" clearable class="marginR10">
						<el-option style="max-width: 500px;" v-for="item in merchantList" :key="item.id"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="广告图片：" prop="shopId">
					<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="activityUploadUrl" :file-list="fileListData" accept="image/jpg,image/png" list-type="picture-card" :limit="1" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
						<el-icon><Plus /></el-icon>
					</el-upload>
					<div class="tips">
						<div>注意：</div>
						<div>1、图片比例：3:4</div>
						<div>2、建议格式：jpg、png</div>
						<div>3、建议数量：1张；</div>
					</div>
					<el-dialog v-model="dialogVisible1">
					    <img w-full :src="dialogImageUrl" alt="Preview Image" />
					</el-dialog>
				</el-form-item>
				<el-form-item label="状态：" prop="shopId">
					<el-radio-group class="marginR20" v-model="formData.enabled">
						<el-radio :label="true">启用</el-radio>
						<el-radio :label="false">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<template #footer>
				<div class="flexAR">
					<el-button type="primary" color="#787878" @click="() => dialogVisible=false">取消</el-button>
					<el-button class="marginR10" type="primary" color="#112DF2" @click="save">保存</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {wechatMaQrcodePageList,wechatMaQrcodeEnabledById,wechatMaQrcodeDeleteById,shopList,merchantList,activityUploadUrl,userLogin} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				activityUploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'activity/homepage-config/banner/'},
				fileListData:[],
				dialogImageUrl:'',
				dialogVisible:false,
				dialogVisible1:false,
				Search,
				pushData:{
					shopId:'',
					merchantId:''
				},
				formData:{
					enabled:true
				},
				type:'1',
				statusList:[
					{name:'全部',status:''},
					{name:'启用',status:'true'},
					{name:'禁用',status:'false'}
				],
				merchantId:'',
				tableData:[],
				merchantList:[],
				shopList:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据',
				rules: {
					shopId: [{required: true,message: '请选择门店名称', trigger: 'blur'}],
					merchantId: [{required: true,message: '请选择商户名称',trigger: 'blur'}]
				}
			}
		},
		created() {
			this.getMerchantList()
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				wechatMaQrcodePageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
							item.isEdit = false
							item.spreadUnitPriceUp = ''
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			getMerchantId(){
				let data = this.pushData
				if(data.merchantId == this.merchantId){
					return
				}
				this.pushData.shopId = ''
				this.merchantId = data.merchantId
				this.search()
				if(data.merchantId){
					this.getShopList()
				}else{
					this.shopList = []
				}
			},
			getMerchantList(){
				let postData = {enabled:true}
				merchantList(postData).then(res => {
					if(res&&res.success){
						this.merchantList = res.data
					}
				})
			},
			getShopList(){
				let postData = {
					enabled:true,
					merchantId:this.merchantId
				}
				shopList(postData).then(res => {
					if(res&&res.success){
						this.shopList = res.data
					}
				})
			},
			inOrOff(id,enabled){
				wechatMaQrcodeEnabledById({data:{id,enabled}}).then(res=>{
					if(res.success){
						this.search()
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			add(){
				this.dialogVisible = true
			},
			delPro(id){
				this.$messageBox.confirm('是否确定删除此条数据？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					wechatMaQrcodeDeleteById({data:{id}}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.search()
						}else{
							this.$message.error(res.msg)
						}
					})
				}).catch(()=>{})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.tags{
		display: inline-block;
		border: 1px solid #999;
		border-radius: 32px;
		padding: 1px 12px;
		box-sizing: border-box;
		font-size: 12px;
	}
</style>