<template>
  <div class="login-container" v-if="!isOldLogin">
    <div class="logoLeft">
    	<div>
			<img src="@/assets/images/logo2.png" alt="">
		</div>
		<div class="logoBg">
			<img src="@/assets/images/logoBg.png" alt="">
		</div>
    </div>
	<div class="logoRight">
		<div class="logoWrap">
			<img src="@/assets/images/logo1.png" alt="">
			<el-form :model="loginForm" status-icon :rules="rules" ref="formLogin" class="form-box">
				<div class="logoText">登 录</div>
				<div>账户</div>
				<el-form-item prop="principal" class="accountName">
					<el-input v-model="loginForm.principal" @keyup.enter="submit" maxlength="20"
					 auto-complete="off" placeholder="输入您的账户" />
				</el-form-item>
				<div>密码</div>
				<el-form-item prop="credentials" class="accountName">
					<el-input type="password" @keyup.enter="submit" v-model="loginForm.credentials"
					 auto-complete="off" placeholder="输入您的密码" />
				</el-form-item>
				<div class="forget">忘记密码？</div>
				<el-button type="primary" class="logoBtn" :loading="loading" @click="submit">登  录</el-button>
			</el-form>
		</div>
	</div>
  </div>
</template>

<script>
import Validate from '@/utils/rules'
import {userLogin,goodsCategoryInfo} from '@/api'
import { ElMessage } from 'element-plus'
export default {
  name: 'Login',
  data () {
    return {
		isOldLogin:false,
		loginForm:{
			principal:'',
			credentials:''
		},
		loading:false,
		rules: {
		  principal: [
		    {required: true, message: '只能输入大小写字母、数字、标点符号',validator: Validate.loginRule, trigger: 'blur'}
		  ],
		  credentials: [
		    {required: true, min: 6, message: '请输入正确格式的密码',validator: Validate.loginRule, trigger: 'blur'}
		  ]
		}
	}
  },
  created() {
	
  },
  methods:{
  	submit(){
		this.$refs.formLogin.validate((valid) => {
			if (!valid) {
				return false
			}
			let postData = {
				"grantType": "PLATFORM_USERNAME_PASSWORD",
				usernamePasswordTokenRequest:{
					principal: this.loginForm.principal,
					credentials: this.loginForm.credentials
				}
			};
			userLogin(postData).then(res => {
				let userInfo = {}
				if(res.success){
					userInfo.token = res.data.accessToken;
					userInfo.refreshToken = res.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.$router.replace('/categoryList')
					// this.$router.replace('/home')
				}else{
					// ElMessage.warning(res.msg)
				}
			})
		})
  	}
  }
}
</script>

<style  rel="stylesheet/scss" lang="scss" scoped>
	.login-container{
		position: fixed;
		left: 0;
		top: 0;
		height: 100vh;
		width: 100%;
		display: flex;
		align-items: center;
		.logoLeft{
			width: 56%;
			height: 100vh;
			background: #112DF2;
			border-radius: 0px 50px 50px 0px;
			padding: 40px;
			box-sizing: border-box;
			position: relative;
			color: #fff;
			font-size: 40px;
			img{
				// width: 212px;
				// height: 70px;
				width:11.04vw;
				height: 6.48vh;
				margin-bottom: 11.85vh;
				// margin-bottom: 128px;
			}
			.logoBg{
				display: flex;
				align-items: center;
				justify-content: center;
				img{
					// width: 588px;
					// height: 648px;
					width:30.6vw;
					height: 60vh;
				}
			}
		}
		.logoRight{
			width: 44%;
			height: 100%;
			// padding-top: 140px;
			padding-top:12.96vh;
			color: #707070;
			font-size: 20px;
			.logoWrap{
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				// margin-bottom: 70px;
				margin-bottom: 6.48vh;
				img{
					// width: 272px;
					// height: 90px;
					width: 14.16vw;
					height:8.33vh;
					margin-bottom: 6.48vh;
				}
				.forget{
					text-align: right;
					color: #E66262;
					margin-bottom: 30px;
				}
				.form-box{
					// width: 638px;
					width: 33.22vw;
					.logoText{
						// margin-bottom: 56px;
						margin-bottom:5.18vh;
						font-size: 30px;
						color: #112DF2;
					}
					.accountName{
						margin-top: 13px;
						::v-deep(.el-input__inner) {
						    // height: 88px;
							height: 8.14vh;
							width: 100%;
							color: #112DF2;
							font-size: 20px;
						}
					}
					.logoBtn{
						width: 100%	;
						height: 8.14vh;
						text-align: center;
						line-height: 8.14vh;
						color: #fff;
						font-size: 30px;
						background: #112DF2;
						border-radius: 10px;
					}
				}				
			}
		}		
	}
</style>
