<template>
	<div>
		<div class="mainWrap flexB">
			<!-- <el-radio-group v-model="type" @change="search()" label="size control" fill='#112DF2' size="default">
				<el-radio-button label="1">客服二维码</el-radio-button>
			    <el-radio-button label="2">领取记录</el-radio-button>
			</el-radio-group> -->
			<h3>关注礼</h3>
			<div class="flexA">
				<el-input v-if="type==2" style="margin-right: 200px;" placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search()" class="w200">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
				<el-button type="warning" @click="importData">导出</el-button>
			</div>
		</div>
		<!-- <div v-if="type==1" class="padL50">
			<div class="flexA padL50 marginB40">
				<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="activityUploadUrl" :file-list="fileListData" accept="image/jpg,image/png" list-type="picture-card" :limit="1" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
					<el-icon><Plus /></el-icon>
				</el-upload>
				<div class="tips">
					<div>注意：</div>
					<div>1、建议尺寸比例：1:1；</div>
					<div>2、建议格式：jpg、png</div>
					<div>3、建议数量：1张；</div>
				</div>
			</div>
			<div class="flexA padL50">
				<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">保 存</el-button>
			</div>
		</div> -->
		<el-table v-if="type==2" :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
		    <el-table-column prop="customerName" label="姓名" />
			<el-table-column prop="customerPhoneNumber" label="手机号" />
			<el-table-column prop="stage" label="阶段">
				<template #default="{row}">
					<div>{{row.stage==1?'怀孕中':row.stage==2?'生产后':row.stage==3?'备孕':''}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="dueDate" label="日期">
				<template #default="{row}">
					<div>{{row.stage==1?'预产期:'+row.dueDate:row.stage==2?'生产后:'+row.childbirthDate:''}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createdTime" label="提交时间" />
		</el-table>
		<div class="paginations" v-if="type==2">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {attentionGiftActivityParticipation,userLogin,attentionGiftActivityParticipationExport} from '@/api'
	import {formatDateOrder} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				// activityUploadUrl,
				// uploadData:{folder:'activity/'},
				importHeaders:{
					'Authorization':''
				},
				fileListData:[],
				Search,
				type:'2',
				pushData:{
					fuzzySearch:''
				},
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			// 确认提交
			submit() {
				this.$refs.formBox.validate((valid) => {
					// if (valid) {
					// 	diamondRegionCreate(this.pushData).then(res=>{
					// 		if(res.success){
					// 			this.$router.replace('/active/diamondRegionList')
					// 		}else{
					// 			this.loading = false
					// 			this.$message.error(res.msg)
					// 		}
					// 	})
					// }
				})
			},
			search(){
				if(this.type==1){
					return
				}
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				attentionGiftActivityParticipation(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			importData(){//导出
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				this.$http.post(attentionGiftActivityParticipationExport, postData, {
					headers:{
						'Authorization': this.$store.state.userInfo.token?('Bearer'+' '+ this.$store.state.userInfo.token):''
					},
					responseType: "blob"
				}).then(res => {
					
					const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
					const fileName = '关注礼用户列表.xlsx';
					if ('download' in document.createElement('a')) {
						const elink = document.createElement('a');
						elink.download = fileName;
						elink.style.display = 'none';
						elink.href = URL.createObjectURL(blob);
						document.body.appendChild(elink);
						elink.click();
						URL.revokeObjectURL(elink.href);
						document.body.removeChild(elink);
					}else{
						navigator.msSaveBlob(blob, fileName);
					}
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
			handlePictureCardPreview(file, fileList,types){
				this.dialogImageUrl = this.pushData.picUrl
				this.dialogVisible1 = true
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || (file.response?ies.url==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || (file.response?ies==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.pushData.picUrl = ''
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.pushData.picUrl = response.data[0]
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 1 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2 && !extension3) {
					this.$message({
						message: "上传模板只能是 .jpg,.png 格式!",
						type: "error"
					});
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2M!");
				}
				return (extension || extension1 || extension2 || extension3) && isLt2M;
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
<style lang="scss" scoped>
	.tips{
		font-size: 13px;
		color: #999;
		display: inline-block;
		margin-left: 10px;
		line-height: 20px;
	}
	.padL50{
		padding-left:50px;
	}
</style>