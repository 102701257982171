<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="" :model-value="dialogVisible" align-center width="600px" style="height: 90vh;" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div class="font13" style="position: relative">
				<div class="fontB marginB10 font16">{{articleItem.title}}</div>
				<div class="flexA marginB10">
					<div class="marginR20">作者：拾悦纪 </div>
					<span class="color606">{{articleItem.nowTime}}</span>
				</div>
				<div class="content" v-html="articleItem.content"></div>
			</div>	
		</el-dialog>
	</div>
</template>
<script>
	import Validate from '@/utils/rules'
	import {formatDateOrder} from '@/utils/data'
	import {} from '@/api'
	export default {
		props: {
			dialogVisiFlag: {
				type: Function,
				default: function() {}
			},
			articleItem:{
				type:Object,
				default:{}
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {}
		},
		methods: {
			hideDialog(){
				this.dialogVisiFlag(false)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}
	.content{
		height: calc(90vh - 200px);
		overflow-y: auto;
		padding: 10px;
		box-sizing: border-box;
	}
</style>
