<template>
	<div>
		<h3 class="marginB12">公众号推广数据</h3>
		<div class="flexB">
			<div class="flexA marginR10">
				<el-select v-model="pushData.state" placeholder="状态" @change="search()" clearable class="marginR10">
					<el-option v-for="item in statusList" :key="item.status"
						:label='item.name' :value="item.status"></el-option>
				</el-select>
				<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" value-format="YYYY-MM-DD" start-placeholder="开始日期"
					end-placeholder="结束日期" />
			</div>
			<div class="flexA">
				<el-button class="marginR10" type="warning" @click="importData">导出</el-button>
				<el-button @click="$router.back()">返回</el-button>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="spreadQrcodeName" label="推广码名称" min-width="100" />
		    <el-table-column prop="shopName" label="门店名称" min-width="90" />
			<el-table-column prop="customerNickName" label="会员名称" min-width="90" />
			<el-table-column prop="customerPhoneNumber" label="手机号" min-width="120" />
			<el-table-column prop="state" label="状态" min-width="90">
				<template #default="{row}">
					<div>{{row.state=='SUBSCRIBE'?'已关注':'取消关注'}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="subscribedTime" label="关注时间" min-width="160" />
			<el-table-column prop="unsubscribedTime" label="取关时间" min-width="160" />
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {spreadQrcodeDataPageList,spreadQrcodeExport} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				statusList:[
					{name:'已关注',status:'SUBSCRIBE'},
					{name:'取消关注',status:'UNSUBSCRIBE'},
				],
				pushData:{},
				timeInterval:[],
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		computed:{
			tCountData () {
			  // const data = this.countData
			  return [
			    {label: '推广数', value: '0'}
			  ]
			}
		},
		created() {
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					spreadQrcodeId:this.$route.params.id?this.$route.params.id:'',
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				if(this.timeInterval&&this.timeInterval.length>0){
					postData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					postData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					postData.beginTime = null
					postData.endTime = null
				}
				postData = Object.assign(postData, this.pushData)
				if(!postData.state){
					delete postData.state
				}
				spreadQrcodeDataPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.subscribedTime = item.subscribedTime?formatDateOrder(item.subscribedTime):''
							item.unsubscribedTime = item.unsubscribedTime?formatDateOrder(item.unsubscribedTime):''
							// item.dataTime = item.updatedTime?formatDateOrder(item.updatedTime):formatDateOrder(item.createdTime)
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			importData(){//导出
				let postData = {
					spreadQrcodeId:this.$route.params.id?this.$route.params.id:''
				}
				if(this.timeInterval&&this.timeInterval.length>0){
					postData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					postData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					postData.beginTime = null
					postData.endTime = null
				}
				postData = Object.assign(postData, this.pushData)
				if(!postData.state){
					delete postData.state
				}
				this.$http.post(spreadQrcodeExport, postData, {
					headers:{
						'Authorization': this.$store.state.userInfo.token?('Bearer'+' '+ this.$store.state.userInfo.token):''
					},
					responseType: "blob"
				}).then(res => {
					const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
					const fileName = '公众号推广数据.xlsx';
					if ('download' in document.createElement('a')) {
						const elink = document.createElement('a');
						elink.download = fileName;
						elink.style.display = 'none';
						elink.href = URL.createObjectURL(blob);
						document.body.appendChild(elink);
						elink.click();
						URL.revokeObjectURL(elink.href);
						document.body.removeChild(elink);
					}else{
						navigator.msSaveBlob(blob, fileName);
					}
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>