<template>
	<div class="el-dialog-body-spacing">
		<el-dialog :title="type=='add'?'新增分类':'编辑分类'" :model-value="dialogVisible" width="600px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<el-form :model="pushData" :rules="rules" ref="formBox" label-width="100px">
					<el-form-item label="上级分类">
						<el-select v-model="pushData.parentId" placeholder="选择上级分类" :disabled="type == 'edit'" style="width: 90%;" clearable class="marginR10">
							<el-option v-for="item in levelList" :key="item.id"
								:label='item.name' :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="分类名称" prop="name">
						<el-input v-model="pushData.name" style="width: 90%;" placeholder="请输入名称" maxlength="6" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="简介">
						<el-input v-model="pushData.intro" maxlength="200" resize="none" rows="5" clearable style="width: 90%" placeholder="填写简介" show-word-limit type="textarea" />
					</el-form-item>
					<el-form-item label="是否启用" prop="enabled">
						<el-radio-group class="marginR20" v-model="pushData.enabled">
							<el-radio :label="true">启用</el-radio>
							<el-radio :label="false">禁用</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import {categoryPsCreate,categoryUpdateById,categoryTopLevelList} from '@/api'
	export default {
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			tagItem:{
				type:Object,
				default:{}
			},
			type:{
				type:String,
				default: 'add'
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				levelList:[],
				pushData:{
					enabled:true
				},
				rules: {
					name: [{required: true,message: "请输入名称",trigger: "blur"}],
					enabled:[{required: true}]
				},
				isRefresh:false
			}
		},
		watch: {
			dialogVisible(v) {
				this.pushData = {
					name:'',
					enabled:true,
					parentId:'',
					intro:''
				}
				if(v){
					if(this.type == 'edit'){
						this.pushData.id = ''
					}
					this.getList()
				}
				if (v && Object.keys(this.tagItem).length) {
					for (let key in this.pushData) {
						this.pushData[key] = this.tagItem[key]
					}
				}
			}
		},
		methods: {
			getList(){
				categoryTopLevelList().then(res=>{
					if(res.success){
						this.levelList = res.data
					}
				})
			},
			confirm(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						if(this.type == 'edit'){
							this.classUpdate()
							return
						}
						categoryPsCreate(this.pushData).then(res=>{
							if(res.success){
								this.isRefresh = true
								this.hideDialog()
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
						
					}
				})
			},
			classUpdate(){
				let data = JSON.parse(JSON.stringify(this.pushData))
				delete data.parentId
				categoryUpdateById({data}).then(res=>{
					if(res.success){
						this.isRefresh = true
						this.hideDialog()
					}else{
						this.loading = false
						this.$message.error(res.msg)
					}
				})
			},
			hideDialog() {
				this.visiFlag(false, this.isRefresh)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}	
</style>
