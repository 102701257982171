<template>
	<div class="wrap">
		<div class='flexB'>
			<span></span>
			<div class='flexA'>
				<el-select v-model="pushData.enabled" placeholder="是否启用" clearable class="marginR10">
					<el-option v-for="item in statusList" :key="item.status"
						:label='item.name' :value="item.status"></el-option>
				</el-select>
				<el-select v-model="pushData.saleable" placeholder="是否可销售" clearable class="marginR10">
					<el-option v-for="item in saleStatusList" :key="item.status"
						:label='item.name' :value="item.status"></el-option>
				</el-select>
				<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search(current)" class="w200">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search(current)"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" ref='tableBox'
			:row-key="getRowKeys" @row-click='clickRow' @selection-change="selectList"
			style="width: 100%;margin-top: 10px;">
			<el-table-column fixed='left' type="selection" width="60" v-if="isShow" :selectable="selectable"
			  :reserve-selection="true"></el-table-column>
		    <el-table-column fixed='left' prop="name" label="产品信息" width="300">
				<template #default="{row}">
					<div class="flexA">
						<el-image class="img80 bgColor marginR10" :src="row.imgsUrl[0]">
							<template #error>
							  <div class="image-slot ">
								<el-icon style='height:80px;'><PictureFilled /></el-icon>
							  </div>
							</template>
						</el-image>
						<div class="textL cardInfo">
							<p>{{row.name}}</p>
							<p>{{row.id}}</p>
							<p class="colorff0">{{row.salePrice?('￥'+row.salePrice):''}}</p>	
						</div>
					</div>
				</template>
			</el-table-column>
		    <el-table-column prop="goodsCategoryName" label="产品类目" width="180">
				<template #default="{row}">
					<div v-if="row.goodsCategoryName" class="color112 bge5e">{{row.goodsCategoryName}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createdTime" label="创建时间" width="180" />
			<el-table-column prop="enabled" label="启用" width="180">
				<template #default="{row}">
					<el-switch
						@change="changeEnabledById(row.enabled,row.id)"
						v-model="row.enabled" disabled 
						style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						:active-value="0" :inactive-value="1"/>
				</template>
			</el-table-column>
			<el-table-column prop="saleable" label="销售" width="180">
				<template #default="{row}">
					<el-switch
						@change="changeSaleableById(row.saleable,row.id)"
						v-model="row.saleable" disabled 
						style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						:active-value="0" :inactive-value="1"/>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {goodsPageList} from '@/api'
	import {formatDate} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		props: {
		  isShow: {
		    type: Boolean,
		    default: false
		  },
		  isChange: {
		    type: Boolean,
		    default: false
		  },
		  selection: {
		    type: Array,
		    default: function() {
		      return []
		    }
		  },
		  option: {
		    type: Object,
		    default: function() {
		      return {}
		    }
		  },
		  isRadio: {
		    type: Boolean,
		    default: false
		  }
		},
		data () {
			return {
				Search,
				pushData:{
					enabled:null,
					saleable:null,
					fuzzySearch:'',
					type:1
				},
				statusList:[{name:'启用',status:'true'},{name:'未启用',status:'false'}],
				saleStatusList:[{name:'销售',status:'true'},{name:'不可销售',status:'false'}],
				current:'all',
				tableData:[],
				pageSize: 5,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		watch: {
		  isChange() {
		    this.isChangeFun()
		  }
		},
		created() {
			this.search('all')
		},	
		methods:{
			search(id){
				if(id){
					this.current = id
				}
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				if(postData.goodsCategoryId == 'all'){
					delete postData.goodsCategoryId
				}
				postData.enabled = postData.enabled == '' ? null : postData.enabled
				postData.saleable = postData.saleable == '' ? null : postData.saleable
				goodsPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDate(item.createdTime)
							item.enabled = item.enabled ? 1 : 0
							item.saleable = item.saleable ? 1 : 0
							item.imgsUrl = []
							if(item.resources&&item.resources.length>0){
								for(let i of item.resources){
									let reg = /\w.(png|jpg|jpeg)$/i
									if(reg.test(i)){
										item.imgsUrl.push(i)
									}
								}
							}
						})
						this.tableData = list
						this.total = Number(res.data.total)
						this.isChangeFun()
					}
				})
			},
			// 选择
			selectList(val) {
			  if (!this.clearSelector) {
			    if (this.isRadio) {
			      // 单选
			      const rows = val[val.length - 1]
			      if (rows) {
			        this.selection.splice(0, 1, rows)
			      } else {
			        this.selection.splice(0, 1)
			      }
			      this.isChangeFun()
			    } else {
			      let s = this.selection
			      s.splice(0, s.length)
			      for (let i = 0; i < val.length; i++) {
			        s.splice(i, 0, val[i])
			      }
			    }
			  }
			},
			selectable(row) {
			  if (!this.isShow) {
			    if (row.state == 1) {
			      if (this.option.smallstate === 1) {
			        if (row.smallstate === 1) {
			          return true
			        } else {
			          return false
			        }
			      } else {
			        return true
			      }
			    } else {
			      return false
			    }
			  } else {
			    return true
			  }
			},
			isChangeFun() {
			  this.$nextTick(() => {
			    let arr = []
			    let backArr = []
			    this.clearSelector = true
			    this.$refs.tableBox.clearSelection()
				
			    for (let i = 0; i < this.selection.length; i++) {
			      for (let j = 0; j < this.tableData.length; j++) {
			        if (this.selection[i].id == this.tableData[j].id) {
			          arr.push(this.tableData[j])
			          break
			        }
			        if (j == this.tableData.length - 1) {
			          backArr.push(this.selection[i])
			        }
			      }
			    }
			    arr.forEach(row => {
			      this.$refs.tableBox.toggleRowSelection(row)
			    })
			    backArr.forEach(row => {
			      this.$refs.tableBox.toggleRowSelection(row)
			    })
			    this.clearSelector = false
			  })
			},
			clickRow(row) {
			  if (this.selectable(row)) {
			    this.$refs.tableBox.toggleRowSelection(row)
			  }
			},
			getRowKeys(row) {
			  return row.id
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search(this.current)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search(this.current)
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.wrap{
		
	}
	.cardInfo{
		width:calc(100% - 90px);
	}
	.item {
	  margin-bottom: 8px;
	  padding: 12px;
	  box-sizing: border-box;
	  font-size: 16px;
	  .span{
		  color: #00B56B;
	  }
	}
	.current{
		color: #fff;
		background: #112DF2;
		border-radius: 10px;
		.span{
			color: #fff;
		}
	}
</style>