<template>
	<div>
		<div class="flexB">
			<el-radio-group v-model="pushData.state" @change="list(pushData.state)" label="size control" fill='#112DF2' size="default">
				<el-radio-button label="all">全部</el-radio-button>
			    <el-radio-button label="UN_PAY">待支付</el-radio-button>
			    <el-radio-button label="DISTRIBUTING">待分配</el-radio-button>
				<el-radio-button label="COMPLETION">已完成</el-radio-button>
				<el-radio-button label="CANCELED">已取消</el-radio-button>
			</el-radio-group>
			<div>
				<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="list(pushData.state)" class="w200">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="list(pushData.state)"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
		    <el-table-column prop="id" label="订单编号" />
			<el-table-column prop="customerNickName" label="会员信息">
				<template #default="{row}">
					<div>{{row.customerNickName+'('+row.customerPhoneNumber+')'}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="shopName" label="所属门店" />
			<el-table-column prop="goodsNameStr" label="商品名称" />
			<el-table-column prop="actualPaymentAmount" label="实付金额" width="100">
				<template #default="scope">
					{{scope.row.state != 'CANCELED'?scope.row.actualPaymentAmount:'--'}}
				</template>
			</el-table-column>
			<el-table-column prop="stateStr" label="状态" width="90" />
			<el-table-column prop="createdTime" label="下单时间" />
			<el-table-column label="操作" width="110">
				<template #default="scope">
					<el-button link type="primary" plain color="#112DF2" @click="viewPro(scope.row.id)">查看</el-button>
					<el-button v-if="scope.row.state=='DISTRIBUTING'" link type="primary" plain color="#112DF2" @click="dialogLayer(scope.row.id,scope.$index)">分配</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="备注">
				<template #default="{row}">
					<div v-if="!row.isEdit">
						<span class="remark">{{row.remark}}</span>
						<el-icon @click="editItem(row.id,row.remark,1)" style='height:10px;margin-left: 6px;'><EditPen /></el-icon>
					</div>
					<div v-if="row.isEdit">
						<el-input style="width: 60%;" v-model="row.remarkUp"></el-input>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.remark,3)">取消</el-button>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.remarkUp,2)">保存</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
		<allocate-shop :dialog-visible.sync="isOpenShop" :close="mergeList" :isShow='true' :is-radio='true'
			:sel-list.sync="selList" ref="child" :activityId='activityId' :visiFlag="visiFlag"></allocate-shop>
	</div>
</template>

<script>
	import {shopList,orderPageList,orderChooseServiceShop,orderUpdateRemarkById} from '@/api'
	import {formatDateOrder,orderStateStr,goodsNameStr} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	import allocateShop from "@/components/PopTable/allocateShop";
	export default {
		components: {allocateShop},
		data () {
			return {
				Search,
				pushData:{
					fuzzySearch:'',
					state:'all'
				},
				shopList:[],
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据',
				activityId:'',
				shopIndex:'',
				isOpenShop:false
			}
		},
		created() {
			this.getShopList()
		},	
		methods:{
			dialogLayer(id,index){
				this.activityId = id
				this.shopIndex = index
				this.$refs.child.search()
				this.isOpenShop = true
			},
			getShopList(){
				let postData = {
						fuzzySearch:'',
						enabled:true
				}
				shopList(postData).then(res => {
					if(res&&res.success){
						this.shopList = res.data
					}
					this.list()
				})
			},
			list(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				postData.state = postData.state=='all'?null:postData.state
				orderPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
							item.stateStr = orderStateStr(item.state)
							item.goodsNameStr = item.goodsNames?goodsNameStr(item.goodsNames[0]):''
							item.remarkUp = ''
							if(item.shopId){
								let shopName = this.shopList.filter(ite=>ite.id==item.shopId)
								item.shopName = shopName.length>0?shopName[0].name:''
							}else{
								item.shopName = ''
							}
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			editItem(id,remark,type){
				this.tableData.forEach(item=>{
					if(item.id == id){
						item.isEdit = !item.isEdit
						if(type==1){
							item.remarkUp = remark
						}
					}
				})
				if(type==2){
					orderUpdateRemarkById({data:{id,remark}}).then(res=>{
						if(res&&res.success){
							this.tableData.forEach(item=>{
								if(item.id == id){
									item.remark = remark
								}
							})
						}
					})
				}
			},
			chooseShop(id,shopId){
				let data = {
					id,
					shopId
				}
				orderChooseServiceShop({data}).then(res=>{
					if(res.success){
						this.pageNum = 1;
						this.list()
						return
					}
					this.$message.error(res.msg)
				})
			},
			visiFlag(val){
				this.isOpenShop = val
			},
			mergeList(data) {
				this.chooseShop(this.tableData[this.shopIndex].id,data[0].id)
			},
			viewPro(id){
				this.$router.push('/orderList/orderView/'+id)
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.list()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.list()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.remark{
		display: inline-block;
		width:80%;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	::v-deep(.el-radio-button__original-radio:checked+.el-radio-button__inner){
		background-color:#112DF2;
		border-color:#112DF2;
		box-shadow:0;
	}
</style>