
// 时间戳转化为日期格式
export function formatDate(timestamp) {
  var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  let D = date.getDate()<10?'0'+date.getDate():date.getDate();
  return Y+M+D;
}
// 订单时间戳转化为日期格式
export function formatDateOrder(timestamp) {
  var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  let D = (date.getDate()<10?'0'+date.getDate():date.getDate()) + ' ';
  let h = date.getHours() + ':';
  let m = (date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()) + ':';
  let s = date.getSeconds()<10?('0'+date.getSeconds()):date.getSeconds();
  return Y+M+D+h+m+s;
}
export function formatDateArtical(timestamp) {
  var date = new Date(timestamp);
  let Y = date.getFullYear() ;
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
  let D = (date.getDate()<10?'0'+date.getDate():date.getDate());
  let h = date.getHours() + ':';
  let m = (date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()) + ':';
  let s = date.getSeconds()<10?('0'+date.getSeconds()):date.getSeconds();
  return Y+'年'+M+'月'+D+'日' + ' '+h+m+	s;
}
//获取N天前日期
export function getRecentDays(numDays) {
  const today = new Date();
  const dates = [];
  for (let i = 0; i < numDays; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    dates.unshift(date.toISOString().split('T')[0]); // 转换为YYYY-MM-DD格式
  }
  return dates;
}
// 首页配置截止时间
export function formatDateIndate(timestamp) {
  var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  let D = (date.getDate()<10?'0'+date.getDate():date.getDate()) + ' ';
  let h = date.getHours() + ':';
  let m = (date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes());
  return Y+M+D+h+m;
}
// 时间戳转化为日期格式
export function formatDateD(timestamp) {
  var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '.';
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '.';
  let D = date.getDate()<10?'0'+date.getDate():date.getDate();
  return Y+M+D;
}
//时间格式
export function dateFormat (timestamp) {
   var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = date.getDate()<10?'0'+date.getDate():date.getDate();
    return Y+M+D;
}
// 开始日期活动
export function activityBeginForm(data) {
  data = data + ' ' + '00:00:00'
  data = data.replace(/-/g,'/');
  var timestamp = new Date(data).getTime()/1000;
  return timestamp
}
// 结束日期活动
export function activityEndForm(data) {
  data = data + ' ' + '23:59:59'
  data = data.replace(/-/g,'/');
  var timestamp = new Date(data).getTime()/1000;
  return timestamp
}
// 订单类型状态
export function orderStateStr(num){
	let str = ''
	if(num == 'UN_PAY'){
		str = '待支付'
	}
	if(num == 'DISTRIBUTING'){
		str = '待分配'
	}
	if(num == 'COMPLETION'){
		str = '已完成'
	}
	if(num == 'CANCELED'){
		str = '已取消'
	}
	if(num == 'GROUPING'){
		str = '拼团中'
	}
	if(num == 'GROUPING_FAILURE'){
		str = '拼团失败'
	}
	return str
}
// 订单类型
export function orderTypeStr(num){
	let str = ''
	if(num == 'NORMAL'){
		str = '普通订单'
	}
	if(num == 'GROUP'){
		str = '拼团活动订单'
	}
	if(num == 'FLASH_SALE'){
		str = '秒杀活动订单'
	}
	if(num == 'BARGAIN'){
		str = '砍价活动订单'
	}
	if(num == 'EXPERIENCE_PRICE'){
		str = '体验价活动订单'
	}
	return str
}
// 活动状态
export function stateStr(num) {
	let str = ''
	if(num == 'NOT_STARTED'){
		str = '未开始'
	}
	if(num == 'IN_REGISTRATION'){
		str = '报名中'
	}
	if(num == 'REGISTRATION_ENDED'){
		str = '报名结束'
	}
	if(num == 'IN_PROGRESS'){
		str = '进行中'
	}
	if(num == 'ENDED'){
		str = '已结束'
	}
	return str
}
// 商品类型
export function typeStr(num) {
	let str = ''
	if(num == 1){
		str = '零售商品'
	}
	if(num == 2){
		str = '产品服务'
	}
	if(num == 3){
		str = '手法服务'
	}
	if(num == 4){
		str = '时卡'
	}
	if(num == 5){
		str = '次卡'
	}
	if(num == 6){
		str = '体验卡'
	}
	return str
}
export function actNameStr(str){
	let name = ''
	if(str.length>10){
		name = str.slice(0, 10)+'…'
	}else{
		name = str
	}
	return name
}
export function goodsNameStr(str){
	let name = ''
	if(str.length>8){
		name = str.slice(0, 8)+'…'
	}else{
		name = str
	}
	return name
}