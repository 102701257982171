<template>
	<div class="mainWrap">
		<div class="mainWrap flexB marginB24">
			<h3>{{edit?'编辑':'新增'}}门店</h3>
			<div class="footerBtn">
				<el-button @click="() => this.$router.go(-1)">取 消</el-button>
				<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">{{edit?'保 存':'添 加'}}</el-button>
			</div>
		</div>
		<el-form :model="pushData" ref="formBox" :rules="rules" label-width="130px" class="formBox">
			<el-row :gutter="16">
				<el-col :span="11">
					<el-form-item label="门店名称" prop="name">
						<el-input v-model="pushData.name" maxlength="40" placeholder="输入门店名称"></el-input>
					</el-form-item>
					<el-row>
						<el-col :span="12">
							<el-form-item label="负责人" prop="head">
								<el-input v-model="pushData.head" maxlength="5" placeholder="输入负责人"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="负责人手机号" prop="headConcat">
								<el-input v-model="pushData.headConcat" maxlength="11" placeholder="输入手机号"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="所属商家" prop="merchantId">
						<el-select v-model="pushData.merchantId" placeholder="请选择所属商家" :disabled='edit' clearable>
							<el-option v-for="item in list" :key="item.id" 
								:label='item.name' :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="门店类目" prop="goodsCategoryIds">
						<el-cascader ref="cascaderAddr" :options="options" :disabled='edit' clearable v-model='pushData.goodsCategoryIds' @change="handleChange" />
					</el-form-item>	
					<el-form-item label="门店状态" prop="enabled">
						<el-radio-group v-model="pushData.enabled">
							<el-radio label="true">启用</el-radio>
							<el-radio label="false">不启用</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="门店展示" prop="display">
						<el-radio-group v-model="pushData.display">
							<el-radio label="true">展示</el-radio>
							<el-radio label="false">隐藏</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-col :span="2"></el-col>
				<el-col :span="8" style="flex-direction: column;" >
					<div class='font14'><span style="color:red;">*</span> 结算账户</div>
					<el-form-item label="" prop="settlementAccount.name" label-width="0">
						<el-input v-model="pushData.settlementAccount.name" maxlength="40" placeholder="账户名称"></el-input>
					</el-form-item>
					<el-form-item label="" prop="settlementAccount.number" label-width="0">
						<el-input v-model="pushData.settlementAccount.number" maxlength="24" placeholder="账户号码"></el-input>
					</el-form-item>
					<el-form-item label="" prop="settlementAccount.bank" label-width="0">
						<el-input v-model="pushData.settlementAccount.bank" maxlength="20" placeholder="所属银行"></el-input>
						<!-- <el-select v-model="pushData.settlementAccount.bank" placeholder="所属银行">
							<el-option v-for="item in list" :key="item.id"
								:label='item.name' :value="item.id"></el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item label="" prop="settlementAccount.accountBank" label-width="0">
						<el-input v-model="pushData.settlementAccount.accountBank" maxlength="40" placeholder="开户行信息"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="详细地址" style="width: 60%;">
						<el-input v-model="pushData.address" placeholder="输入详细地址"></el-input>
					</el-form-item>
				</el-col>
				<el-row>
					<el-col :span="12">
						<el-form-item label="经度">
							<el-input v-model="pushData.longitude" placeholder="输入经度"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="纬度">
							<el-input v-model="pushData.latitude" placeholder="输入纬度"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="门店头图" style="width:90%;" prop="name">
					<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="depUploadUrl" :file-list="fileListData" multiple accept="image/jpg,image/png" list-type="picture-card" :limit="6" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
						<el-icon><Plus /></el-icon>
					</el-upload>
					<div class="tips">
						<div>头图注意：</div>	
						<div>1、建议尺寸：600px*600px；</div>
						<div>2、建议格式：jpg、png；</div>
						<div>3、建议数量：6张；</div>
					</div>	
					<el-dialog v-model="dialogVisible">
					    <img w-full :src="dialogImageUrl" alt="Preview Image" />
					</el-dialog>
				</el-form-item>
				<el-form-item label="门店简介" style="width:70%;">
					<QuillEditor ref='quillRef' v-model:content="pushData.introduction" style="width:100%" :options="editorOption" contentType="html" />
				</el-form-item>
			</el-row>
		</el-form>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {depUploadUrl,goodsCategoryList,merchantList,shopCreate,shopUpdate,shopInfo,userLogin} from '@/api'
	// 工具栏配置
	const toolbarOptions = [
	    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
	    ["blockquote", "code-block"], // 引用
	    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
	    [{ script: "sub" }, { script: "super" }], // 上标/下标
	    [{ indent: '-1' }, { indent: '+1' }], // 缩进
	    [{ direction: 'rtl' }], // 文本方向
	    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
	    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
	    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
	    [{ font: [] }], // 字体种类
	    [{ align: [] }], // 对齐方式
	    ['clean'], // 清除文本格式
	    ['link', 'image', 'video'] // 链接、图片、视频
	]
	import {QuillEditor, Quill } from '@vueup/vue-quill'
	import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
	import quillTool from '@/utils/quillTool'
	Quill.register(quillTool, true)
	Quill.register('modules/ImageExtend', ImageExtend)
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	export default{
		components: {QuillEditor},
		data (){
			return{
				depUploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'shop/'},
				fileListData:[],
				imgUrl:[],
				dialogImageUrl:'',
				dialogVisible:false,
				pushData:{
					name:'',
					head:'',
					headConcat:'',
					merchantId:'',
					enabled:'true',
					display:'true',
					introduction:'',
					goodsCategoryIds:'',
					resources:[],
					settlementAccount:{
						"shopId": 0,
						name:'',
						number:'',
						bank:'',
						accountBank:''
					}
				},
				list:[],
				options:[],
				rules: {
					name: [{required: true, message: '请输入门店名称', trigger: 'blur'}],
					head: [{required: true, message: '请输入负责人', trigger: 'blur'}],
					headConcat: [{required: true,message: '请输入负责人手机号',validator: Validate.isTel, trigger: 'blur'}],
					merchantId: [{required: true, message: '请选择所属商家', trigger: 'blur'}],
					parentId: [{required: true, message: '请选择门店类目', trigger: 'blur'}],
					enabled:[{required: true}],
					display:[{required: true}],
					goodsCategoryIds:[{required: true, message: '请选择门店类目', trigger: 'blur'}],
					'settlementAccount.name': [{required: true, message: '请输入账户名称', trigger: 'blur'}],
					'settlementAccount.number': [{required: true, message: '请输入账户号码', trigger: 'blur'}],
					'settlementAccount.bank': [{required: true, message: '请选择所属银行', trigger: 'blur'}],
					'settlementAccount.accountBank': [{required: true, message: '请输入开户行信息', trigger: 'blur'}]
				},
				loading: false,
				edit: false,
				editorOption: {
					theme: 'snow',
					placeholder: '请输入内容',
					modules: {
						ImageExtend: {
							name: 'files', // 参数名
							action: depUploadUrl, // 服务器地址，如果为空则采用base64插入图片
							headers: xhr => { // 设置请求头参数（选填）
								xhr.setRequestHeader("Authorization", (this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''))
							},
							response: res => {
								if(res.code=="A0200"||res.code=="A0201"){// 登录过期，重新登录
									this.$store.commit("updateUserInfo", '');
									this.$router.push("/")
									return
								}
								if(res.code=="A0202"){
									this.userLogin()
									return
								}
								return res.data[0]
							},
							size: 8, // 图片不能超过8M
							sizeError: () => {
								this.$message.error('粘贴图片大小不能超过8MB!')
							}
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function(value) {
									QuillWatch.emit(this.quill.id)
								},
								link: function(value) {
									if (value) {
										var href = prompt('请输入链接地址：')
										this.quill.format('link', href)
									} else {
										this.quill.format('link', false)
									}
								},
								video: function(value) {
									if (value) {
										var href = prompt('请输入视频链接：')
										this.quill.format('video', href)
									} else {
										this.quill.format('video', false)
									}
								}
							}
						}
					}
				}
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			this.getData()
			this.getCategoryList()
			if(this.$route.params.id){
				this.getDetails(this.$route.params.id)
				this.edit = true
			}
		},	
		methods:{
			handleChange(e) {
				this.pushData.goodsCategoryIds = this.$refs['cascaderAddr'].getCheckedNodes()[0].value
			},
			getDetails(){
				let postData = {id:this.$route.params.id}
				shopInfo({data:postData}).then(res => {
					if(res.success){
						let data = res.data
						this.$nextTick(() => {
							this.$refs.quillRef.setContents(data.introduction)
						})
						data.enabled =  data.enabled.toString() 
						data.display =  data.display.toString() 
						if(data.resources&&data.resources.length>0){
							data.resources.forEach(ies =>{
								this.fileListData.push({url: ies});
								this.imgUrl.push(ies)
							});
						}
						delete data.resources
						this.pushData = data
					}
				})
			},
			getData(){
				let postData = {
					enabled:true,
					goodsCategoryId:'',
					fuzzySearch:''
				}
				merchantList(postData).then(res=>{
					if(res.success){
						this.list = res.data
					}
				})
			},
			getCategoryList(){
				goodsCategoryList({data:{
						parentId:0,level:1
					}}).then(res=>{
					if(res.success){
						let data = res.data
						let arr = []
						if(data.length>0){
							data.forEach(item=>{
								item.label = item.name
								item.value = item.id
								if(item.children&&item.children.length>0){
									for (let i of item.children) {
										i.label = i.name
										i.value = i.id
									}
								}
								arr.push(item)
							})
						}
						this.options = arr
					}
				})
			},
			// 确认提交
			submit() {
				if(this.imgUrl.length==0){
					this.$message.error('请上传门店头图')
					return
				}
				if(this.pushData.longitude&&this.pushData.longitude.length>12){
					this.$message.error('请设置长度小于12的经度')
					return
				}
				if(this.pushData.latitude&&this.pushData.latitude.length>12){
					this.$message.error('请设置长度小于12的纬度')
					return
				}
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						this.loading = true
						this.pushData.resources = this.imgUrl
						let pushData = {
							  "name": this.pushData.name,
							  "head": this.pushData.head,
							  "headConcat": this.pushData.headConcat,
							  "introduction": this.pushData.introduction,
							  "enabled": this.pushData.enabled,
							  "display": this.pushData.display,
							  "resources": this.imgUrl,
							  "settlementAccount": this.pushData.settlementAccount,
							  "goodsCategoryIds":this.pushData.goodsCategoryIds,
							  "merchantId":this.pushData.merchantId,
							  "address":this.pushData.address,
							  "longitude":this.pushData.longitude,
							  "latitude":this.pushData.latitude
						}
						// pushData = Object.assign(pushData,this.pushData)
						if(this.edit){
							pushData.id = this.pushData.id
							delete pushData.goodsCategoryIds
							delete pushData.merchantId
						}
						let url = this.edit ? shopUpdate : shopCreate
						url(pushData).then(res=>{
							if(res.success){
								this.$router.replace('/merchantManagement/storeManagement')
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || (file.response?ies.url==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || (file.response?ies==file.response.data[0]:'')){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || (file.response?ies==file.response.data[0]:'')){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 6 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2 && !extension3) {
					this.$message({
						message: "上传模板只能是 .jpg,.png 格式!",
						type: "error"
					});
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2M!");
				}
				return (extension || extension1 || extension2 || extension3) && isLt2M;
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	
</style>