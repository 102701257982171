<template>
	<div class="el-dialog-body-spacing">
		<el-dialog :title="type=='add'?'新增标签':'编辑标签'" :model-value="dialogVisible" width="600px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<el-form :model="pushData" :rules="rules" ref="formBox" label-width="120px">
					<el-form-item label="名称" prop="name">
						<el-input v-model="pushData.name" placeholder="请输入名称" maxlength="6" style="width: 85%" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="是否启用">
						<el-switch
							v-model="pushData.enabled" 
							style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
							:active-value="false" :inactive-value="true"/>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import {tagPsCreate,tagPsUpdateById} from '@/api'
	export default {
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			tagItem:{
				type:Object,
				default:{}
			},
			type:{
				type:String,
				default: 'add'
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				pushData:{
					name:'',
					enabled:true
				},
				rules: {
					name: [{required: true,message: "请输入名称",trigger: "blur"} ]
				},
				isRefresh:false
			}
		},
		watch: {
			dialogVisible(v) {
				if (v) {
					if(this.type == 'edit'){
						this.pushData.id = this.tagItem.id
						this.pushData.name = this.tagItem.name
						this.pushData.enabled = this.tagItem.enabled
					}else{
						this.pushData = {
							name:'',
							enabled:true
						}
					}
				}
			}
		},
		methods: {
			confirm(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						let url = this.type == 'edit' ? tagPsUpdateById : tagPsCreate
						url({data:this.pushData}).then(res=>{
							if(res.success){
								this.isRefresh = true
								this.hideDialog()
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
					}
				})
				// this.visiFlag()
			},
			hideDialog() {
				this.visiFlag(false, this.isRefresh)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}	
</style>
